import { Pipe, PipeTransform } from '@angular/core';
import { TaskStatusTypeEnum } from '@zaoblako/shared/modules/task/enums/task.status.type.enum';

const taskListURLMap = {
  [TaskStatusTypeEnum.TASK_STATUS_TYPE_OPENED]: '/task/opened',
  [TaskStatusTypeEnum.TASK_STATUS_TYPE_ACCEPTED]: '/task/accepted',
  [TaskStatusTypeEnum.TASK_STATUS_TYPE_COMPLETED]: '/task/completed',
  [TaskStatusTypeEnum.TASK_STATUS_TYPE_CANCELED]: '/task/canceled',
  [TaskStatusTypeEnum.TASK_STATUS_ALL]: '/task/all',
  [TaskStatusTypeEnum.TASK_STATUS_ACTIVE]: '/task/active'
};

@Pipe({
  name: 'taskListUrlByStatus'
})
export class TaskListUrlByStatusPipe implements PipeTransform {
  public transform(taskStatusType: TaskStatusTypeEnum): string {
    return taskListURLMap[taskStatusType] || '/';
  }
}
