<div class="p-input-icon-right w-full">
  <input type="text" pInputText placeholder="Поиск по CRM" class="w-full" #searchInput (input)="search.next(searchInput.value)" />
  <i class="pi pi-search"></i>
</div>

<div class="relative" *ngIf="(searchResults$ | async) as results">
  <div class="search-results">

    <div *ngIf="results.client?.length">
      <div class="search-results__result-header fw-semibold"><i class="pi pi-id-card text-lg mr-2"></i>Клиенты</div>
      <a *ngFor="let client of results.client" class="search-results__result" routerLink="/client/card/{{client.id}}" target="_blank">
        <p class="fw-semibold">{{client.name}}</p>
      </a>
    </div>

    <div *ngIf="results.contract?.length">
      <div class="search-results__result-header fw-semibold"><i class="pi pi-file text-lg mr-2"></i>Договоры</div>
      <a *ngFor="let contract of results.contract" class="search-results__result" routerLink="/contract/card/{{contract.id}}" target="_blank">
        <p class="fw-semibold">{{contract.name}} №{{contract.number}}</p>
        <p class="greytext">{{contract.person.client.name}}: {{contract.person.form?.abbreviation}} "{{contract.person.name}}"</p>
      </a>
    </div>

    <div *ngIf="results.task?.length">
      <div class="search-results__result-header fw-semibold"><i class="pi pi-book text-lg mr-2"></i>Заявки</div>
      <a *ngFor="let task of results.task" class="search-results__result" routerLink="/task/card/{{task.id}}" target="_blank">
        <p class="fw-semibold">{{task.name}}</p>
        <p class="greytext">{{task.template.name}}</p>
      </a>
    </div>

    <div *ngIf="results.comment?.length">
      <div class="search-results__result-header fw-semibold"><i class="pi pi-comments text-lg mr-2"></i>Комментарии к заявкам</div>
      <a *ngFor="let comment of results.comment" class="search-results__result" routerLink="/task/card/{{comment.task.id}}" target="_blank">
        <p class="fw-semibold">{{comment.task.name}}</p>
        <div class="mt-3 flex align-items-center">
          <p class="mr-2 fw-semibold">{{comment.createdBy?.name}}</p>
          <p class="greytext">{{comment.created | date:'dd.MM.yyyy HH:mm':'GMT+3'}}</p>
        </div>
        <div class="mt-1">
          <div [innerHTML]="comment.text | safeHtml" class="ql-editor ql-comment"></div>
        </div>
      </a>
    </div>

    <div *ngIf="!results.comment?.length && !results.client?.length && !results.task?.length && !results.contract?.length" class="p-3 fw-semibold text-lg">
      <i class="pi pi-search mr-2"></i>Ничего не найдено
    </div>

  </div>
</div>
