export enum TaskAgreementStepEnum {
  /**
   * Запрос аренды
   * Новая заявка, общая информация из формы регистрации
   */
  TASK_STEP_INFO = 'INFO',
  /**
   * Коммерческое предложение
   * Согласование помещения
   */
  TASK_STEP_ROOM_AGREE = 'ROOM_AGREE',
  /**
   * Подбор помещения
   * Внутреннее согласование
   */
  TASK_STEP_ROOM = 'ROOM',
  /**
   * Подписание договора
   */
  TASK_STEP_CONTRACT = 'CONTRACT',
}
