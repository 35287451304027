export enum HistoryActionEnum {
  /**
   * Создание
   */
  HISTORY_ACTION_CREATE = 'CREATE',

  /**
   * Обновление
   */
  HISTORY_ACTION_UPDATE = 'UPDATE',

  /**
   * Добавление тэга
   */
  HISTORY_ACTION_ADD_TAG = 'ADD_TAG',

  /**
   * Добавление в согласователи
   */
  HISTORY_ACTION_ADD_AGREEMENT = 'ADD_AGREEMENT',

  /**
   * Удаление из согласователей
   */
  HISTORY_ACTION_REMOVE_AGREEMENT = 'REMOVE_AGREEMENT',

  /**
   * Добавление в аудиторы
   */
  HISTORY_ACTION_ADD_AUDITOR = 'ADD_AUDITOR',

  /**
   * Удаление из аудиторов
   */
  HISTORY_ACTION_REMOVE_AUDITOR = 'REMOVE_AUDITOR',

  /**
   * Добавление родителя
   */
  HISTORY_ACTION_ADD_PARENT = 'ADD_PARENT',

  /**
   * Удаление тэга
   */
  HISTORY_ACTION_REMOVE_TAG = 'REMOVE_TAG',

  /**
   * Удаление
   */
  HISTORY_ACTION_DELETE = 'DELETE',

  /**
   * Согласование заявки
   */
  HISTORY_ACTION_AGREE = 'AGREE',

  /**
   * Отказ в согласовании заявки
   */
  HISTORY_ACTION_DISAGREE = 'DISAGREE',

  /**
   * Изменение / добавление значения поля
   */
  HISTORY_ACTION_VALUE = 'VALUE',

  /**
   * Согласование этапа заявки
   */
  HISTORY_ACTION_AGREE_STEP = 'AGREE_STEP',

  /**
   * Отказ в согласовании этапа заявки
   */
  HISTORY_ACTION_DISAGREE_STEP = 'DISAGREE_STEP',

  /**
   *  Обновление этапа заявки
   */
  HISTORY_ACTION_UPDATE_STEP = 'UPDATE_STEP',

  /**
   * Обновление статуса заявки
   */
  HISTORY_ACTION_UPDATE_STATUS = 'UPDATE_STATUS',

  /**
   * Объединение помещений - история в результирующем помещении
   */
  HISTORY_ACTION_MERGE_ROOMS_CHILD = 'MERGE_ROOMS_CHILD',

  /**
   * Объединение помещений - история в родительских помещениях
   */
  HISTORY_ACTION_MERGE_ROOMS_PARENT = 'MERGE_ROOMS_PARENT',

  /**
   * Разделение помещений - история в результирующих помещениях
   */
  HISTORY_ACTION_SPLIT_ROOMS_CHILD = 'SPLIT_ROOMS_CHILD',

  /**
   * Разделение помещений - история в родительском помещении
   */
  HISTORY_ACTION_SPLIT_ROOMS_PARENT = 'SPLIT_ROOMS_PARENT',

  /**
   * Освобождение помещения
   */
  HISTORY_ACTION_FREE_ROOM = 'FREE_ROOM',

  /**
   * Добавление условия в договор
   * Например, 'добавил(-а) условие "Фиксация курса"'
   */
  HISTORY_ACTION_ADD_CONTRACT_SERVICE = 'ADD_CONTRACT_SERVICE',

  /**
   * Удаление условия из договора
   * Например, 'удалил(-а) условие "Фиксация курса"'
   */
  HISTORY_ACTION_REMOVE_CONTRACT_SERVICE = 'REMOVE_CONTRACT_SERVICE',

  /**
   * Изменение одного из полей условия договора
   * Например, 'изменил(-а) поле "Курс: мин." с "25" на "40" в "Фиксация курса"'
   */
  HISTORY_ACTION_UPDATE_CONTRACT_SERVICE_FIELD = 'UPDATE_CONTRACT_SERVICE_FIELD',

  /**
   * Привязка договора к помещению
   */
  HISTORY_ACTION_ROOM_ADD_CONTRACT = 'ROOM_ADD_CONTRACT',

  /**
   * Замена одного договора у помещения на другой
   */
  HISTORY_ACTION_ROOM_UPDATE_CONTRACT = 'ROOM_UPDATE_CONTRACT',
}
