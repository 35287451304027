import { BaseDto } from '@zaoblako/shared/common/dtos/base.dto';
import { DtoMessageEnum } from '@zaoblako/shared/common/enums/dto.message.enum';
import { Expose } from 'class-transformer';
import { IsDate, IsNumber, IsString } from 'class-validator';

@Expose()
export class TaskRegistrationDto extends BaseDto {

  @Expose()
  @IsNumber({}, { message: DtoMessageEnum.REQUIRED_FIELD })
  public squareFrom: number;

  @Expose()
  @IsNumber({}, { message: DtoMessageEnum.REQUIRED_FIELD })
  public squareTo: number;

  @Expose()
  @IsString({ message: DtoMessageEnum.REQUIRED_FIELD })
  public activities: string;

  @Expose()
  @IsNumber({},{ message: DtoMessageEnum.REQUIRED_FIELD })
  public plans: number;

  @Expose()
  @IsString({ message: DtoMessageEnum.REQUIRED_FIELD })
  public site: string;

  @Expose()
  @IsNumber({}, { message: DtoMessageEnum.REQUIRED_FIELD })
  public rentMoth: number;

  @Expose()
  @IsDate({ message: DtoMessageEnum.REQUIRED_FIELD })
  public dateFrom: Date;

  @Expose()
  @IsString({ message: DtoMessageEnum.REQUIRED_FIELD })
  public trademarks: string;


}
