import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InformerTypeEnum } from '@zaoblako/shared/common/enums/informer.type.enum';
import { UnreadNotificationItemDto } from '@zaoblako/shared/modules/notification/dtos/unread.notification.item.dto';
import { RoleEnum } from '@zaoblako/shared/modules/security/enums/role.enum';
import { NotificationService } from '@zaoblako/web/core/services/notification.service';
import { AuthService } from '@zaoblako/web/core/services/security/auth.service';
import { WebsocketNotificationService } from '@zaoblako/web/core/services/websocket-notification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'crm-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit, OnDestroy {
  @Input() public informerType: InformerTypeEnum;
  @Input() public icon: string;
  @Input() public header: string;
  public loading = false;
  public notifications: UnreadNotificationItemDto[] = [];
  public unreadCount = 0;
  private newWSMessageSubscription: Subscription;
  private wsNotificationReadSubscription: Subscription;
  private unreadCountSubscription: Subscription;
  private readNotificationSubscription: Subscription;
  private reloadOnOpen = true;
  private readId: number;

  public urlActivity: string;

  public get InformerType() {
    return InformerTypeEnum;
  }

  public constructor(
    private readonly authService: AuthService,
    private readonly websocketNotificationService: WebsocketNotificationService,
    private readonly notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    if (this.authService.hasRole(RoleEnum.ROLE_ADMIN)) {
      this.urlActivity = '/activity';
    } else if (this.authService.hasRole(RoleEnum.ROLE_COOWNER)) {
      this.urlActivity = '/coowner/activity';
    } else if (this.authService.hasRole(RoleEnum.ROLE_OPERATION)) {
      this.urlActivity = '/operation/activity';
    } else if (
      this.authService.hasRole([RoleEnum.ROLE_USER_DIRECTOR, RoleEnum.ROLE_USER_MANAGER, RoleEnum.ROLE_USER])
    ) {
      this.urlActivity = '/manager/activity';
    } else if (this.authService.hasRole(RoleEnum.ROLE_SECURITY)) {
      this.urlActivity = '/security/activity';
    } else {
      this.urlActivity = '/activity';
    }

    this.newWSMessageSubscription = this.websocketNotificationService.getNewMessage().subscribe(
      (socketMessage) => {
        if (socketMessage.message.informerType === this.informerType) {
          this.websocketNotificationService.showNotificationPopup(socketMessage.message);
          this.unreadCount++;
          this.notifications = [];
          this.reloadOnOpen = true;
        }
      },
      (err) => console.error(err),
      () => console.log('complete')
    );

    this.wsNotificationReadSubscription = this.websocketNotificationService.socketNotificationRead$.subscribe(
      (notification) => {
        if (notification.informerType === this.informerType) {
          this.unreadCount--;
        }
      }
    );

    this.unreadCountSubscription = this.notificationService.unreadCount$.subscribe((unreadCount) => {
      this.unreadCount = unreadCount[this.informerType] ?? 0;
    });

    this.readNotificationSubscription = this.notificationService.eventReadNotification.subscribe((informerType) => {
      if (this.informerType === informerType) {
        this.unreadCount--;
      }
    });
  }

  public ngOnDestroy() {
    this.newWSMessageSubscription?.unsubscribe();
    this.wsNotificationReadSubscription?.unsubscribe();
    this.unreadCountSubscription?.unsubscribe();
    this.readNotificationSubscription?.unsubscribe();
  }

  public reloadNotifications(): void {
    if (this.reloadOnOpen) {
      this.loadNotifications();
    }
  }

  public loadNotifications(): void {
    this.loading = true;
    this.notificationService.findUnreadByType(this.informerType).subscribe(
      (notifications) => {
        this.notifications = notifications;
        this.reloadOnOpen = false;
        this.loading = false;
      },
      () => (this.loading = false)
    );
  }

  public read(notification: UnreadNotificationItemDto): void {
    if (this.readId === notification.id) {
      return;
    }

    this.notificationService.read(notification.id, this.informerType).subscribe(
      () => {
        this.notifications.splice(this.notifications.indexOf(notification), 1);
        this.unreadCount -= notification.count;
        this.readId = null;
      },
      () => (this.readId = null)
    );
  }

  public readAll(): void {
    this.notificationService.readAll(this.informerType).subscribe({
      next: () => {
        this.notifications = [];
        this.unreadCount = 0;
      }
    });
  }
}
