import { Component, Input } from '@angular/core';
import { TaskCommentFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.comment.find.dto';

@Component({
  selector: 'crm-dashboard-recent-comments',
  templateUrl: './dashboard-recent-comments.component.html',
  styleUrls: [
    '../../../../../../../../node_modules/quill/dist/quill.core.css',
    '../../../../../../../../node_modules/quill/dist/quill.snow.css'
  ]
})
export class DashboardRecentCommentsComponent {

  @Input() public comments: TaskCommentFindDto[] = [];
  @Input() public loading = false;

  public skeletonItems = Array(5).fill(null);

}
