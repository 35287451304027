import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserEmailDto } from '@zaoblako/shared/modules/security/dtos/user/user.email.dto';
import { UserLoginDto } from '@zaoblako/shared/modules/security/dtos/user/user.login.dto';
import { RoutingService } from '@zaoblako/web/core/services/routing.service';
import { AuthService } from '@zaoblako/web/core/services/security/auth.service';
import { UserStateService } from '@zaoblako/web/core/services/security/user-state.service';
import { validate } from '@zaoblako/web/core/services/validation/validate.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'crm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loading = false;
  public isReset = false;

  public login: UserLoginDto = new UserLoginDto();
  public terms: { is: boolean, error: string | undefined } = {
    is: true,
    error: undefined
  };
  public errorsLogin: Record<keyof UserLoginDto, any[]>;

  public resetEmail: UserEmailDto = new UserEmailDto();
  public errorsEmail: Record<keyof UserEmailDto, any[]>;
  public isSendResetEmail = false;

  public errBack: any;
  public footerYear: string;
  private url: string;
  private queryParams: any;

  public constructor(private readonly authService: AuthService,
                     private readonly userStateService: UserStateService,
                     private readonly route: ActivatedRoute,
                     private readonly routingService: RoutingService,
                     private readonly router: Router) {
  }

  public ngOnInit() {
    this.url = this.route.snapshot.queryParams['url'] || '/';
    this.queryParams = this.url.split('?')[1] || '';

    this.url = this.url.split('?')[0] || this.url;
    this.queryParams = this.queryParams ? JSON.parse('{"' + decodeURI(this.queryParams.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}') : {};

    if (moment().year() === 2021) {
      this.footerYear = '2021';
    } else {
      this.footerYear = `2021-${moment().year()}`;
    }

    if (this.authService.currentUser) {
      if (this.url === '/') {
        this.routingService.redirectToLk();
      } else {
        this.router.navigate([this.url], { queryParams: this.queryParams }).catch(console.error);
      }
    }
  }

  public loginClick() {
    this.loading = true;

    if (!this.terms.is) {
      this.loading = false;
      this.terms.error = 'Необходимо принять соглашение';
      return;
    }

    this.terms.error = undefined;
    this.login.password = this.login.password?.trim();

    const { valid, errors } = validate(this.login, UserLoginDto);
    if (!valid) {
      this.errorsLogin = errors;
      console.log(this.errorsLogin);
      this.loading = false;
    } else {
      this.errorsLogin = null;
      this.authService.login(this.login).subscribe({
        next: () => {
          this.loading = false;
          if (this.url === '/') {
            this.routingService.redirectToLk();
          } else {
            this.router.navigate([this.url], { queryParams: this.queryParams }).catch(console.error);
          }
        },
        error: (err) => {
          this.loading = false;
          this.errBack = err;
        }
      });
    }
  }

  public resetClick() {
    this.loading = true;

    const { valid, errors } = validate(this.resetEmail, UserEmailDto);
    if (!valid) {
      this.errorsEmail = errors;
      console.log(this.errorsEmail);
      this.loading = false;
    } else {
      this.errorsEmail = null;
      this.userStateService.restorePassword(this.resetEmail).subscribe(() => {
        this.loading = false;
        this.resetEmail.email = '';
        this.isSendResetEmail = true;
      }, () => this.loading = false);
    }
  }

  public change() {
    this.isReset = !this.isReset;
    this.isSendResetEmail = false;
    if (this.isReset && this.login.email && !this.resetEmail.email) {
      this.resetEmail.email = this.login.email;
    }
  }

}
