import { Component, Input } from '@angular/core';
import { UserFindDto } from '@zaoblako/shared/modules/security/dtos/user/user.find.dto';
import { UserLoginResponseDto } from '@zaoblako/shared/modules/security/dtos/user/user.login.response.dto';
import { CommentUserInterface } from '@zaoblako/web/core/models/comment-user.interface';

@Component({
  selector: 'crm-user-avatar',
  templateUrl: './user-avatar.component.html'
})
export class UserAvatarComponent {

  @Input() public user: CommentUserInterface | UserLoginResponseDto | UserFindDto;
  @Input() public size = 'large';
  @Input() public shape = 'square';

  public get userLabel() {
    const [firstName, secondName] = (this.user.name || '').split(' ');
    return (firstName ? firstName[0] : '') + (secondName ? secondName[0] : '');
  }

}
