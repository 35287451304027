import { DecimalPipe } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from '@zaoblako/web/core/components/app.component';
import { CoreRouter } from '@zaoblako/web/core/core.router';
import { GlobalErrorHandler } from '@zaoblako/web/core/services/error-handler.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ScrollTopModule } from 'primeng/scrolltop';
import { ToastModule } from 'primeng/toast';
import { MaintenancePageComponent } from './components/maintenance-page/maintenance-page.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    MaintenancePageComponent
  ],
  imports: [
    CoreRouter,
    ToastModule,
    ConfirmDialogModule,
    ScrollTopModule
  ],
  providers: [
    MessageService,
    ConfirmationService,
    DecimalPipe,
    [
      { provide: ErrorHandler, useClass: GlobalErrorHandler }
    ]
  ],
  exports: [],
  bootstrap: []
})
export class CoreModule {
}
