import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TaskStepsCountDto } from '@zaoblako/shared/modules/report/dtos/task-steps/task.steps.count.dto';
import { TaskAgreementStepEnum } from '@zaoblako/shared/modules/task/enums/task.agreement.step.enum';
import * as moment from 'moment-timezone';

@Component({
  selector: 'crm-dashboard-task-steps',
  templateUrl: './dashboard-task-steps.component.html',
  styleUrls: [
    './dashboard-task-steps.component.scss'
  ]
})
export class DashboardTaskStepsComponent implements OnChanges {

  @Input() public taskStepsCount: TaskStepsCountDto;
  public taskSteps: { name: string, routerLink?: string, queryParams?: any, value: number, color: string }[] = [];

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.taskStepsCount && changes.taskStepsCount.currentValue) {
      this.taskSteps = [
        {
          name: 'Предварительное согласование',
          value: this.taskStepsCount.steps[TaskAgreementStepEnum.TASK_STEP_INFO]?.count,
          color: '#cd5c5c',
          routerLink: '/task/active',
          queryParams: {
            templateIds: this.taskStepsCount.templateIds,
            steps: this.taskStepsCount.steps[TaskAgreementStepEnum.TASK_STEP_INFO]?.step.name,
            from: '2014-01-01',
            to: moment().format('YYYY-MM-DD')
          }
        },
        {
          name: this.taskStepsCount.steps[TaskAgreementStepEnum.TASK_STEP_ROOM]?.step.name,
          value: this.taskStepsCount.steps[TaskAgreementStepEnum.TASK_STEP_ROOM]?.count,
          color: '#fbdc08',
          routerLink: '/task/active',
          queryParams: {
            templateIds: this.taskStepsCount.templateIds,
            steps: this.taskStepsCount.steps[TaskAgreementStepEnum.TASK_STEP_ROOM]?.step.name,
            from: '2014-01-01',
            to: moment().format('YYYY-MM-DD')
          }
        },
        {
          name: this.taskStepsCount.steps[TaskAgreementStepEnum.TASK_STEP_ROOM_AGREE]?.step.name,
          value: this.taskStepsCount.steps[TaskAgreementStepEnum.TASK_STEP_ROOM_AGREE]?.count,
          color: '#39c517',
          routerLink: '/task/active',
          queryParams: {
            templateIds: this.taskStepsCount.templateIds,
            steps: this.taskStepsCount.steps[TaskAgreementStepEnum.TASK_STEP_ROOM_AGREE]?.step.name,
            from: '2014-01-01',
            to: moment().format('YYYY-MM-DD')
          }
        },
        {
          name: 'Подписание договора',
          value: this.taskStepsCount.steps[TaskAgreementStepEnum.TASK_STEP_CONTRACT]?.count,
          color: '#38a9e1',
          routerLink: '/task/active',
          queryParams: {
            templateIds: this.taskStepsCount.templateIds,
            steps: this.taskStepsCount.steps[TaskAgreementStepEnum.TASK_STEP_CONTRACT]?.step.name,
            from: '2014-01-01',
            to: moment().format('YYYY-MM-DD')
          }
        },
        {
          name: 'Завершена',
          value: this.taskStepsCount.completed,
          color: '#cd5c5c',
          routerLink: '/task/completed',
          queryParams: {
            templateIds: this.taskStepsCount.templateIds,
            from: '2014-01-01',
            to: moment().format('YYYY-MM-DD')
          }
        }
      ];
    }
  }

  public getStepStyles(index: number, color: string) {
    const styles = {
      'border-top-width.px': 52,
      'border-top-color': color,
      'width.px': 290 - 30 * index
    };

    if (index === this.taskSteps.length - 1) {
      styles['border-left'] = 'none';
      styles['border-right'] = 'none';
    }

    return styles;
  }

}
