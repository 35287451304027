<div class="grid">
  <div class="col-6 md:col-3 xl:col-2" *ngFor="let report of reports">
    <a [routerLink]="report.link">
      <p-card styleClass="h-full dashboard-report-card">
        <a class="text-lg">{{ report.name }}</a>
        <p class="greytex mt-3">{{ report.description }}</p>
      </p-card>
    </a>
  </div>
</div>
