import { BaseDto } from '@zaoblako/shared/common/dtos/base.dto';
import { DtoMessageEnum } from '@zaoblako/shared/common/enums/dto.message.enum';
import { Expose, Transform } from 'class-transformer';
import { IsEmail, IsString } from 'class-validator';

@Expose()
export class UserRegistrationDto extends BaseDto {

  @Expose()
  @IsString({ message: DtoMessageEnum.MISSED_USER_NAME })
  public name: string;

  @Expose()
  @IsString({ message: DtoMessageEnum.REQUIRED_FIELD })
  public phone: string;

  @Expose()
  @IsEmail({}, { message: DtoMessageEnum.INCORRECT_EMAIL })
  @Transform(element => element.value?.toLowerCase())
  public email: string;

}
