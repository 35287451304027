import { BaseDto } from '@zaoblako/shared/common/dtos/base.dto';
import { DtoMessageEnum } from '@zaoblako/shared/common/enums/dto.message.enum';
import { Expose } from 'class-transformer';
import { IsString } from 'class-validator';

@Expose()
export class ClientRegistrationDto extends BaseDto {

  @Expose()
  @IsString({ message: DtoMessageEnum.REQUIRED_FIELD })
  public name: string;

}
