export enum DtoMessageEnum {

  REQUIRED_FIELD = 'Поле обязательно для заполнения',

  MISSED_USER_NAME = 'Не указано имя пользователя',

  INCORRECT_EMAIL = 'Неверный адрес email',

  INCORRECT_JOB = 'Неверная должность',

  INCORRECT_PHONE = 'Неверный телефон',

  MINIMUM_ROLE = 'Для пользователя необходимо задать хотя бы $constraint1 роль(и)',

}
