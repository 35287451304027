<div
  class="bg-bluegray-100 fixed bottom-0 {{ !isLk ? 'notification-panel' : 'w-full' }}"
  [class.minimized]="minimized && !isLk"
  style="z-index: 1000"
>
  <div class="px-3 py-1 flex flex-wrap align-items-center">
    <div class="notification-grid {{ !isLk ? 'p-2' : '' }}">
      <crm-global-search *ngIf="!isLk"></crm-global-search>
    </div>
    <div class="flex">
      <crm-notification-list
        [icon]="'pi-book'"
        header="Заявки"
        [informerType]="InformerType.TASK"
      ></crm-notification-list>
      <crm-notification-list
        [icon]="'pi-id-card'"
        header="Каталог"
        [informerType]="InformerType.CATALOG"
      ></crm-notification-list>
      <crm-notification-list
        [icon]="'pi-dollar'"
        header="Бюджет"
        [informerType]="InformerType.BUDGET"
      ></crm-notification-list>
      <crm-notification-list
        [icon]="'pi-info-circle'"
        header="ОФД"
        [informerType]="InformerType.SYSTEM"
      ></crm-notification-list>
    </div>
  </div>
</div>

<p-toast key="notification" position="bottom-right" styleClass="notification-toast" #toast>
  <ng-template let-message pTemplate="message">
    <div
      *ngIf="toSocketNotification(message.data) as notification"
      class="w-full cursor-pointer"
      (click)="onNotificationClick(toast, notification)"
    >
      <h4 class="mt-0 mb-3 fw-bold">{{ notification.entity?.name || 'Новое уведомление' }}</h4>
      <h4 class="mt-0 mb-1 fw-semibold">{{ notification?.user.name }}</h4>
      <p class="notification-text break-word">{{ notification.text | truncate: 70 }}</p>
    </div>
  </ng-template>
</p-toast>
