import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TaskFieldDiscriminatorEnum } from '@zaoblako/shared/modules/task/enums/task.field.discriminator.enum';

const typeNames: Record<TaskFieldDiscriminatorEnum, string> = {
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_BOOLEAN]: 'Да/Нет',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_DATE]: 'Дата',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_DIRECTION]: 'Бюджетная статья',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_CONTRACT]: 'Договор',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_CURRENCY]: 'Валюта',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_TIME]: 'Время',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_DATETIME]: 'Дата и время',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_TIME_PERIOD]: 'Период времени',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_TEXT]: 'Текст',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_TABLE]: 'Таблица',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_REVENUE]: 'Выручка',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_TEXTAREA]: 'Текстовая область',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_NUMBER]: 'Число',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_PHONE]: 'Телефон',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_FILE]: 'Файл',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_FILES]: 'Файлы',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_UNIT]: 'Бизнес единица',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_USER]: 'Пользователь',
  [TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_USERS]: 'Пользователи'
};

@Injectable()
@Pipe({
  name: 'taskFieldType'
})
export class TaskFieldTypePipe implements PipeTransform {
  public transform(taskFieldType: TaskFieldDiscriminatorEnum): string {
    return typeNames[taskFieldType];
  }
}
