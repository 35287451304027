<h1>Рабочий стол</h1>
<p-tabMenu [model]="periods" [activeItem]="activePeriod"></p-tabMenu>

<h2>Статистика</h2>
<crm-dashboard-stats-widgets [periodStats]="stats && stats[activePeriod.state.name]"
                             [roomStats]="stats && stats.room"
                             [debt]="stats && stats.debt"
                             [activePeriodLabel]="activePeriod.label"
                             [activePeriodName]="activePeriod.state.name">
</crm-dashboard-stats-widgets>

<div class="mt-2">
  <crm-dashboard-task-stats [taskStats]="taskStats && taskStats[activePeriod.state.name]"
                            [activePeriodLabel]="activePeriod.label"
                            [activePeriodName]="activePeriod.state.name">
  </crm-dashboard-task-stats>
</div>

<div class="mt-2">
  <div class="grid">
    <div class="col-12 lg:col-4 h-400px">
      <crm-dashboard-task-steps [taskStepsCount]="taskStepsCount"></crm-dashboard-task-steps>
    </div>
    <div class="col-12 lg:col-4 h-400px">
      <crm-dashboard-client-indexes [clientIndexes]="stats && stats.clientIndexes"></crm-dashboard-client-indexes>
    </div>
    <div class="col-12 lg:col-4 h-400px">
      <crm-dashboard-expiring-contracts [contracts]="stats && stats.expiringContracts"></crm-dashboard-expiring-contracts>
    </div>
  </div>
</div>

<h2>Отчеты</h2>
<crm-dashboard-reports></crm-dashboard-reports>

<div class="grid mt-4">
  <div class="col-12 lg:col-6">
    <p-card>
      <h3 class="mt-2">Активность пользователей</h3>
      <p-divider></p-divider>
      <crm-task-history [taskHistory]="taskHistory" [loading]="historyLoading" [showTaskInfo]="true"></crm-task-history>
    </p-card>
  </div>
  <div class="col-12 lg:col-6">
    <crm-dashboard-recent-comments [comments]="recentComments" [loading]="recentCommentsLoading"></crm-dashboard-recent-comments>
  </div>
</div>

