export enum TaskFieldDiscriminatorEnum {
  TASK_FIELD_TYPE_BOOLEAN = 'BOOLEAN',
  TASK_FIELD_TYPE_DATE = 'DATE',
  TASK_FIELD_TYPE_DIRECTION = 'DIRECTION',
  TASK_FIELD_TYPE_CONTRACT = 'CONTRACT',
  TASK_FIELD_TYPE_CURRENCY = 'CURRENCY',
  TASK_FIELD_TYPE_TIME = 'TIME',
  TASK_FIELD_TYPE_DATETIME = 'DATETIME',
  TASK_FIELD_TYPE_TEXT = 'TEXT',
  TASK_FIELD_TYPE_TABLE = 'TABLE',
  TASK_FIELD_TYPE_REVENUE = 'REVENUE',
  TASK_FIELD_TYPE_TEXTAREA = 'TEXTAREA',
  TASK_FIELD_TYPE_NUMBER = 'NUMBER',
  TASK_FIELD_TYPE_PHONE = 'PHONE',
  TASK_FIELD_TYPE_FILE = 'FILE',
  TASK_FIELD_TYPE_FILES = 'FILES',
  TASK_FIELD_TYPE_UNIT = 'UNIT',
  TASK_FIELD_TYPE_USER = 'USER',
  TASK_FIELD_TYPE_USERS = 'USERS',
  TASK_FIELD_TYPE_TIME_PERIOD = 'TIME_PERIOD'
}
