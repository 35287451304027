<div class="grid">
  <div class="col-12 lg:col-6 xl:col-6">
    <crm-dashboard-stat-widget-card title="Трафик"
                                    [description]="activePeriodLabel"
                                    [activePeriodName]="activePeriodName"
                                    [withPeriodQueryParams]="true"
                                    link="/report/traffic">
      <ng-container *ngIf="periodStats; else statSkeleton">
        <p class='fw-semibold'>{{periodStats.traffic | number }} человек</p>
      </ng-container>
      <i class="pi pi-users color-teal ml-3 text-2xl"></i>
    </crm-dashboard-stat-widget-card>
  </div>
  <div class="col-12 lg:col-6 xl:col-6">
    <crm-dashboard-stat-widget-card title="Загруженность"
                                    [activePeriodName]="activePeriodName"
                                    link="/location/free">
      <ng-container *ngIf="roomStats; else statSkeleton">
        <p class='fw-semibold'>{{roomStats.active | number }} ({{roomsLoad | number:'.0-0'}}%) м²</p>
      </ng-container>
      <i class="pi pi-chart-bar color-teal ml-3 text-2xl"></i>
    </crm-dashboard-stat-widget-card>
  </div>
<!--  <div class="col-12 lg:col-6 xl:col-4">-->
<!--    <crm-dashboard-stat-widget-card title="Задолженность"-->
<!--                                    [activePeriodName]="activePeriodName"-->
<!--                                    link="/report/debt">-->
<!--      <p class='fw-semibold' *ngIf="debt; else statSkeleton">{{debt.amount | number }}</p>-->
<!--      <p-tag value="с НДС" styleClass="white-space-nowrap ml-3"></p-tag>-->
<!--      <i class="pi pi-exclamation-triangle color-teal ml-3 text-2xl"></i>-->
<!--    </crm-dashboard-stat-widget-card>-->
<!--  </div>-->
  <div class="col-12 lg:col-6 xl:col-4">
    <crm-dashboard-stat-widget-card title="Платежи"
                                    [description]="activePeriodLabel"
                                    [activePeriodName]="activePeriodName"
                                    [withPeriodQueryParams]="true"
                                    link="/report/payment">
      <p class='fw-semibold' *ngIf="periodStats; else statSkeleton">{{periodStats.payment | number:'.0-2' }}</p>
      <p-tag value="с НДС" styleClass="white-space-nowrap ml-3"></p-tag>
      <i class="pi pi-chart-line color-teal ml-3 text-2xl"></i>
    </crm-dashboard-stat-widget-card>
  </div>
  <div class="col-12 lg:col-6 xl:col-4">
    <crm-dashboard-stat-widget-card title="Исходящие платежи"
                                    [description]="activePeriodLabel"
                                    [activePeriodName]="activePeriodName"
                                    [withPeriodQueryParams]="true"
                                    link="/report/outgoing">
      <p class='fw-semibold' *ngIf="periodStats; else statSkeleton">{{periodStats.outgoing | number:'.0-2' }}</p>
      <p-tag value="с НДС" styleClass="white-space-nowrap ml-3"></p-tag>
      <i class="pi pi-chart-line color-teal ml-3 text-2xl"></i>
    </crm-dashboard-stat-widget-card>
  </div>
  <div class="col-12 lg:col-6 xl:col-4">
    <crm-dashboard-stat-widget-card title="Выручка арендаторов"
                                    [description]="activePeriodLabel"
                                    [activePeriodName]="activePeriodName"
                                    [withPeriodQueryParams]="true"
                                    link="/report/revenue">
      <p class='fw-semibold' *ngIf="periodStats; else statSkeleton">{{periodStats.receipt | number:'.0-2' }}</p>
      <p-tag value="с НДС" styleClass="white-space-nowrap ml-3"></p-tag>
      <i class="pi pi-chart-line color-teal ml-3 text-2xl"></i>
    </crm-dashboard-stat-widget-card>
  </div>
  <ng-template #statSkeleton>
    <p-skeleton width="120px" height="25px"></p-skeleton>
  </ng-template>
</div>
