<p-card>
  <h3 class="mt-2">Последние комментарии
    <ng-container *ngIf="comments.length">({{comments.length}})</ng-container>
  </h3>
  <p-divider></p-divider>
  <ng-container *ngIf="!loading; else commentsSkeleton">
    <div class="flex mt-5" *ngFor="let comment of comments; index as index">
      <div class="ml-2 mr-4">
        <crm-user-avatar [user]="comment.createdBy"></crm-user-avatar>
      </div>
      <div class="w-full">
        <div class="flex align-items-center">
          <a class="color-black fw-semibold" routerLink="/user/{{comment.createdBy.id}}">{{comment.createdBy.name}}</a>
          <p class="greytext ml-2">{{comment.created | humanDate}}</p>
        </div>
        <div class="mt-1">
          <div [innerHTML]="comment.text | safeHtml" class="ql-editor ql-comment"></div>
        </div>
        <a class="block greytext mt-2" routerLink="/task/card/{{comment.task.id}}">
          <p>{{comment.task.name}}</p>
          <p>{{comment.task.template?.name}}</p>
        </a>
      </div>
    </div>
  </ng-container>
</p-card>

<ng-template #commentsSkeleton>
  <div class="flex mt-5" *ngFor="let _ of skeletonItems">
    <div class="ml-2 mr-4">
      <p-skeleton shape="square" size="3rem"></p-skeleton>
    </div>
    <div class="w-full">
      <p-skeleton width="30%" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="70%" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="60%"></p-skeleton>
    </div>
  </div>
</ng-template>
