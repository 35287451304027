import { BaseDto } from '@zaoblako/shared/common/dtos/base.dto';
import { DtoMessageEnum } from '@zaoblako/shared/common/enums/dto.message.enum';
import { Expose, Transform } from 'class-transformer';
import { IsEmail, IsOptional } from 'class-validator';

@Expose()
export class UserEmailDto extends BaseDto {

  @Expose()
  @IsOptional()
  @IsEmail({}, { message: DtoMessageEnum.INCORRECT_EMAIL })
  @Transform(element => element.value ? element.value.toLowerCase() : undefined)
  public email: string;

}
