import { Pipe, PipeTransform } from '@angular/core';
import { DashboardPeriodType } from '@zaoblako/web/core/types/dashboard-period.type';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'concatDateParams'
})

export class ConcatDateParamsPipe implements PipeTransform {
  public transform(queryParams: Record<string, any>, period: DashboardPeriodType): Record<string, any> {
    let from = '', to = '';

    if (period === 'lastYear') {
      from = moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD');
      to = moment().endOf('year').subtract(1, 'year').format('YYYY-MM-DD');
    } else {
      from = moment().startOf(period).format('YYYY-MM-DD');
      to = moment().endOf(period).format('YYYY-MM-DD');
    }

    return { ...queryParams, from, to };
  }
}
