<div class="notfound-background-container">
  <div class="text-center">
    <i class="pi pi-exclamation-circle text-gray-200 text-8xl"></i>
    <h1 class="text-gray-200 text-6xl">Страница не найдена</h1>
    <div>
      <button pButton label="Назад" icon="pi pi-arrow-left" class="p-button-lg mr-4" (click)="redirectBack()"></button>
      <button pButton label="На главную" icon="pi pi-home" class="p-button-lg p-button-secondary" (click)="redirectToHomepage()"></button>
    </div>
  </div>
</div>
