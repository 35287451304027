import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccessEnum } from '@zaoblako/shared/modules/security/enums/access.enum';
import { ErrorService } from '@zaoblako/web/core/services/error.service';
import { AuthService } from '@zaoblako/web/core/services/security/auth.service';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class AclInterceptor implements HttpInterceptor {

  public constructor(private readonly authService: AuthService, private readonly errorService: ErrorService) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const currentUser = this.authService.currentUser;

    if (!currentUser) {
      return next.handle(req);
    }

    const accessType = ['GET', 'HEAD', 'OPTIONS'].includes(req.method) ? AccessEnum.READ : AccessEnum.WRITE;

    let currentAcl;

    for (const url of Object.keys(currentUser.acl)) {

      const match = new RegExp(url.split('/').map(path => path[0] === ':' ? path = '[a-zA-Z0-9]+' : path).filter(path => !!path).join('/'));

      if (match.test(req.url)) {
        currentAcl = currentUser.acl[url];

        if ([AccessEnum.READ, AccessEnum.WRITE].includes(currentAcl) && accessType === AccessEnum.READ) {
          return next.handle(req);
        } else if (currentAcl === AccessEnum.WRITE && accessType === AccessEnum.WRITE) {
          return next.handle(req);
        }
      }
    }

    const error = { status: 403, message: 'Нет прав для выполнения HTTP запроса ' + req.method + ' ' + req.url };
    this.errorService.addDefaultError(error);
    return throwError(error);

  }
}
