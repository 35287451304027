import { Component, OnInit } from '@angular/core';
import { IndexReportDto } from '@zaoblako/shared/modules/report/dtos/index/index.report.dto';
import { TaskStepsCountDto } from '@zaoblako/shared/modules/report/dtos/task-steps/task.steps.count.dto';
import { TaskReportDto } from '@zaoblako/shared/modules/report/dtos/task/task.report.dto';
import { TaskCommentFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.comment.find.dto';
import { TaskHistoryFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.history.find.dto';
import { ReportStateService } from '@zaoblako/web/core/services/report/report-state.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'crm-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public periods: MenuItem[] = [
    { label: 'Текущий день', command: event => this.setActivePeriod(event.item), state: { name: 'day' } },
    { label: 'Текущая неделя', command: event => this.setActivePeriod(event.item), state: { name: 'week' } },
    { label: 'Текущий месяц', command: event => this.setActivePeriod(event.item), state: { name: 'month' } }
  ];
  public activePeriod: MenuItem;

  public stats: IndexReportDto;
  public taskStats: TaskReportDto;
  public taskHistory: TaskHistoryFindDto[] = [];
  public recentComments: TaskCommentFindDto[] = [];
  public taskStepsCount: TaskStepsCountDto;

  public historyLoading = false;
  public recentCommentsLoading = false;

  public constructor(private readonly reportStateService: ReportStateService) {
  }

  public ngOnInit() {
    const activePeriodName = localStorage.getItem('dashboard.activePeriod');

    this.activePeriod = activePeriodName
      ? this.periods.find(period => period.state.name === activePeriodName) ?? this.periods[0]
      : this.periods[0];

    this.reportStateService.dashboardStatWidgets().subscribe(stats => {
      this.stats = stats;
    });

    this.reportStateService.dashboardTaskWidgets().subscribe(taskStats => {
      this.taskStats = taskStats;
    });

    this.historyLoading = true;
    this.reportStateService.dashboardTaskHistory().subscribe(history => {
      this.taskHistory = history;
      this.historyLoading = false;
    });

    this.recentCommentsLoading = true;
    this.reportStateService.dashboardRecentComments().subscribe(comments => {
      this.recentComments = comments;
      this.recentCommentsLoading = false;
    });

    this.reportStateService.taskStepsCount().subscribe(taskStepsCount => {
      this.taskStepsCount = taskStepsCount;
    });
  }

  public setActivePeriod(period: MenuItem) {
    this.activePeriod = period;
    localStorage.setItem('dashboard.activePeriod', period.state.name);
  }
}
