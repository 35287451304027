import { Injectable } from '@angular/core';
import { RegistrationDto } from '@zaoblako/shared/modules/security/dtos/user/registration.dto';
import { UserFindDto } from '@zaoblako/shared/modules/security/dtos/user/user.find.dto';
import { RegisterService } from '@zaoblako/web/core/services/security/register.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterStateService {

  public constructor(private readonly registerService: RegisterService) { }

  public register(body: RegistrationDto): Observable<UserFindDto> {
    return this.registerService.register(body);
  }
}
