<ng-container *ngIf="!loading; else userActivitySkeleton">
  <ng-container *ngFor="let history of taskHistory; index as index">
    <div *ngIf="!minimized || index < 25" class="flex mt-5">
      <div class="ml-2 mr-4">
        <crm-user-avatar [user]="history.user"></crm-user-avatar>
      </div>
      <div class="w-full">
        <div class="greytext">{{history.created | humanDate}}</div>
        <div>
          <a class="color-black fw-semibold" routerLink="/user/{{history.user.id}}">{{history.user.name}}</a>&nbsp;
          <ng-container [ngSwitch]="history.action">
            <span *ngSwitchCase="HistoryActionEnum.HISTORY_ACTION_CREATE">
              создал(а) заявку<ng-template [ngTemplateOutlet]="taskLink"></ng-template>
            </span>
            <span *ngSwitchCase="HistoryActionEnum.HISTORY_ACTION_DELETE">
              удалил(а) заявку<ng-template [ngTemplateOutlet]="taskLink"></ng-template>
            </span>
            <span *ngSwitchCase="HistoryActionEnum.HISTORY_ACTION_UPDATE_STATUS">
              изменил(а) статус заявки<ng-template [ngTemplateOutlet]="taskLink"></ng-template><span>: {{history.data}}</span>
            </span>
            <span *ngSwitchCase="HistoryActionEnum.HISTORY_ACTION_AGREE">
              согласовал(а) заявку<ng-template [ngTemplateOutlet]="taskLink"></ng-template>
            </span>
            <span *ngSwitchCase="HistoryActionEnum.HISTORY_ACTION_DISAGREE">
              отклонил(а) заявку<ng-template [ngTemplateOutlet]="taskLink"></ng-template>
            </span>
            <span *ngSwitchCase="HistoryActionEnum.HISTORY_ACTION_AGREE_STEP">
              согласовал(а) этап "{{history.data}}"
              <ng-container *ngIf="showTaskInfo">в заявке&nbsp;</ng-container><ng-template [ngTemplateOutlet]="taskLink"></ng-template>
            </span>
            <span *ngSwitchCase="HistoryActionEnum.HISTORY_ACTION_DISAGREE_STEP">
              отклонил(а) этап "{{history.data}}"
              <ng-container *ngIf="showTaskInfo">в заявке&nbsp;</ng-container><ng-template [ngTemplateOutlet]="taskLink"></ng-template>
            </span>
            <span *ngSwitchCase="HistoryActionEnum.HISTORY_ACTION_UPDATE_STEP">
              изменил(а) этап "{{history.data}}"
              <ng-container *ngIf="showTaskInfo">в заявке&nbsp;</ng-container><ng-template [ngTemplateOutlet]="taskLink"></ng-template>
            </span>
            <span *ngSwitchCase="HistoryActionEnum.HISTORY_ACTION_UPDATE">
              обновил(а) заявку<ng-template [ngTemplateOutlet]="taskLink"></ng-template><span>: {{history.data}}</span>
            </span>
            <span *ngSwitchCase="HistoryActionEnum.HISTORY_ACTION_ADD_TAG">
              добавил(а) тег к заявке<ng-template [ngTemplateOutlet]="taskLink"></ng-template><span>: {{history.data}}</span>
            </span>
            <span *ngSwitchCase="HistoryActionEnum.HISTORY_ACTION_REMOVE_TAG">
              удалил(а) тег из заявки<ng-template [ngTemplateOutlet]="taskLink"></ng-template><span>: {{history.data}}</span>
            </span>
            <span *ngSwitchCase="HistoryActionEnum.HISTORY_ACTION_VALUE">
              изменил(а) поле в заявке<ng-template [ngTemplateOutlet]="taskLink"></ng-template><span>: {{history.data}}</span>
            </span>
            <span *ngSwitchCase="HistoryActionEnum.HISTORY_ACTION_ADD_AUDITOR">
              добавил аудитора к заявке<ng-template [ngTemplateOutlet]="taskLink"></ng-template><span>: {{history.data}}</span>
            </span>
            <span *ngSwitchCase="HistoryActionEnum.HISTORY_ACTION_REMOVE_AUDITOR">
              удалил аудитора из заявки<ng-template [ngTemplateOutlet]="taskLink"></ng-template><span>: {{history.data}}</span>
            </span>
            <span *ngSwitchCase="HistoryActionEnum.HISTORY_ACTION_ADD_AGREEMENT">
              добавил согласование к заявки<ng-template [ngTemplateOutlet]="taskLink"></ng-template><span>: {{history.data}}</span>
            </span>
            <span *ngSwitchCase="HistoryActionEnum.HISTORY_ACTION_REMOVE_AGREEMENT">
              удалил согласование из заявки<ng-template [ngTemplateOutlet]="taskLink"></ng-template><span>: {{history.data}}</span>
            </span>
          </ng-container>
          <p *ngIf="showTaskInfo" class="greytext">
            <small>{{history.task.template?.name}}</small>
          </p>
          <ng-template #taskLink>
            <a *ngIf="showTaskInfo" routerLink="/task/card/{{history.task.id}}">&nbsp;{{history.task.name}}</a>
          </ng-template>
        </div>
        <div class="mt-3"></div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="taskHistory.length >= 25">
    <p-divider></p-divider>
    <button (click)="minimized = !minimized"
            [icon]="minimized ? 'pi pi-window-maximize' : 'pi pi-window-minimize'"
            [label]="minimizeButtonLabel()"
            class="p-button-text p-button-secondary"
            pButton>
    </button>
  </ng-container>
</ng-container>

<ng-template #userActivitySkeleton>
  <div *ngFor="let _ of skeletonItems" class="flex mt-5">
    <div class="ml-2 mr-4">
      <p-skeleton shape="square" size="3rem"></p-skeleton>
    </div>
    <div class="w-full">
      <p-skeleton styleClass="mb-2" width="10%"></p-skeleton>
      <p-skeleton width="100%"></p-skeleton>
    </div>
  </div>
</ng-template>
