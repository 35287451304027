export enum PeriodEnum {
  QUARTER = 'QUARTER',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH'
}

export enum ExtendedPeriodEnum {
  QUARTER = 'QUARTER',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}
