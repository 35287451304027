<p-card styleClass="h-full">
  <h3 class="mt-2 mb-0">Индексы арендаторов</h3>
  <p-divider></p-divider>

  <crm-table name="dashboard.clientIndexes"
             [values]="clientIndexes"
             [columns]="clientTableColumns"
             scrollHeight="290px">
    <ng-template #rowTemplate let-clientIndex="item" let-rowIndex="rowIndex">
      <ng-container *ngIf="getClient(clientIndex)">
        <tr>
          <td>
            <div class="word-break-all">
              <p>
                {{rowIndex + 1}}
                <a routerLink="/client/card/{{getClient(clientIndex).id}}">{{getClient(clientIndex).name}}</a>
              </p>
              <p>
                <a class="greytext" routerLink="/location/room/card/{{toClientIndex(clientIndex).room.id}}">
                  {{toClientIndex(clientIndex).room.number}} {{toClientIndex(clientIndex).room.description}}
                </a>
              </p>
            </div>
          </td>
          <td [ngClass]="toClientIndex(clientIndex).GMI | contractIndexClass:'GMI'" style="width: 80px">
            {{toClientIndex(clientIndex).GMI | number:'.0-0' }}
          </td>
          <td [ngClass]="toClientIndex(clientIndex).HI | contractIndexClass:'HI'" style="width: 80px">
            {{toClientIndex(clientIndex).HI | number:'.0-0'}}</td>
          <td [ngClass]="toClientIndex(clientIndex).RI | contractIndexClass:'RI'" style="width: 80px">
            {{toClientIndex(clientIndex).RI | number:'.0-0'}}
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </crm-table>
</p-card>
