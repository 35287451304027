<i
  *ngIf="!unreadCount"
  class="pi {{ icon }} mx-3 my-3 text-2xl cursor-pointer p-text-secondary"
  (click)="op.toggle($event)"
>
</i>
<i
  *ngIf="unreadCount"
  class="pi {{ icon }} mx-3 my-3 text-2xl cursor-pointer p-text-secondary"
  pBadge
  severity="danger"
  [value]="unreadCount > 99 ? '99+' : unreadCount.toString()"
  (click)="op.toggle($event)"
>
</i>

<p-overlayPanel
  #op
  [style]="{ width: '400px' }"
  styleClass="dropdown-overlay-panel informer-overlay-panel"
  (onShow)="loadNotifications()"
>
  <ng-template pTemplate>
    <div class="flex justify-content-between align-items-center py-2 text-white" style="background: rgb(73, 84, 105)">
      <div class="flex align-items-center">
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-refresh"
          class="p-button-rounded text-white p-button-text mx-1"
          (click)="loadNotifications()"
        ></button>
        <p class="fw-bold">{{ header }}</p>
      </div>
      <div>
        <button
          pButton
          label="Подробнее"
          icon="pi pi-window-maximize"
          class="text-white p-button-text hover:text-blue-300 py-0"
          (click)="op.hide()"
          [routerLink]="urlActivity"
          [queryParams]="{ informerType: informerType }"
        ></button>
        <p *ngIf="notifications?.length" class="text-right">
          <span class="cursor-pointer hover:text-blue-300 text-sm mr-3" (click)="readAll()">Прочитать все</span>
        </p>
      </div>
    </div>

    <ng-container *ngIf="loading; else notificationsTemplate">
      <div class="text-center">
        <p-progressSpinner></p-progressSpinner>
      </div>
    </ng-container>
    <ng-template #notificationsTemplate>
      <div class="overflow-y-auto" style="max-height: 400px">
        <div
          *ngFor="let notification of notifications | orderBy: '-created:date'"
          class="border-bottom-1 border-gray-200"
        >
          <div class="flex">
            <div class="p-3">
              <crm-user-avatar [user]="notification.createdBy"></crm-user-avatar>
            </div>
            <div class="py-3 pr-3 pb-2 w-full">
              <div class="flex justify-content-between">
                <div>
                  <p class="fw-bold">{{ notification.createdBy?.name }}</p>
                  <p class="greytext">{{ notification.created | date: 'dd.MM.yyyy HH:mm' }}</p>
                </div>
                <p-tag
                  *ngIf="notification.count > 1"
                  [value]="notification.count?.toString()"
                  styleClass="mr-2"
                  [rounded]="true"
                ></p-tag>
              </div>
              <p class="fw-semibold mb-1 mt-3">{{ notification.entity?.name }}</p>
              <p
                class="break-word"
                style="max-height: 200px; overflow: hidden"
                [innerHTML]="notification.text | safeHtml"
              ></p>
              <div class="flex mt-2">
                <button
                  pButton
                  label="Перейти"
                  [icon]="'pi pi-reply'"
                  (click)="read(notification)"
                  class="p-button-secondary p-button-text p-button-sm"
                  [routerLink]="notification | convertNotificationToLink"
                  [queryParams]="notification | convertNotificationToLink: true"
                ></button>
                <button
                  pButton
                  label="Прочитать"
                  [icon]="'pi pi-eye'"
                  class="p-button-secondary p-button-text p-button-sm ml-2"
                  (click)="read(notification)"
                ></button>
              </div>
            </div>
          </div>
        </div>

        <h4 *ngIf="!notifications?.length" class="text-center">
          {{ unreadCount === 0 ? 'Нет новых уведомлений' : 'Обновите список, для просмотра непрочитанных уведомлений' }}
        </h4>
      </div>
    </ng-template>
  </ng-template>
</p-overlayPanel>
