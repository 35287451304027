import { Injectable } from '@angular/core';
import { AreaOccupationReportDto } from '@zaoblako/shared/modules/report/dtos/area/area.occupation.report.dto';
import { AreaOccupationReportQueryDto } from '@zaoblako/shared/modules/report/dtos/area/area.occupation.report.query.dto';
import { AutocheckReportDto } from '@zaoblako/shared/modules/report/dtos/autocheck/autocheck.report.dto';
import { AutocheckReportQueryDto } from '@zaoblako/shared/modules/report/dtos/autocheck/autocheck.report.query.dto';
import { BalanceReportDto } from '@zaoblako/shared/modules/report/dtos/balance/balance.report.dto';
import { BalanceReportQueryDto } from '@zaoblako/shared/modules/report/dtos/balance/balance.report.query.dto';
import { ContractDocumentReportDto } from '@zaoblako/shared/modules/report/dtos/contract-document/contract.document.report.dto';
import { ContractDocumentReportQueryDto } from '@zaoblako/shared/modules/report/dtos/contract-document/contract.document.report.query.dto';
import { ContractServiceReportDto } from '@zaoblako/shared/modules/report/dtos/contract/contract.service.report.dto';
import { ContractServiceReportQueryDto } from '@zaoblako/shared/modules/report/dtos/contract/contract.service.report.query.dto';
import { DebtReportDto } from '@zaoblako/shared/modules/report/dtos/debt/debt.report.dto';
import { DebtReportQueryDto } from '@zaoblako/shared/modules/report/dtos/debt/debt.report.query.dto';
import { DiscountReportDto } from '@zaoblako/shared/modules/report/dtos/discount/discount.report.dto';
import { DiscountReportQueryDto } from '@zaoblako/shared/modules/report/dtos/discount/discount.report.query.dto';
import { DocumentReportDto } from '@zaoblako/shared/modules/report/dtos/document/document.report.dto';
import { DocumentReportQueryDto } from '@zaoblako/shared/modules/report/dtos/document/document.report.query.dto';
import { IndexReportDto } from '@zaoblako/shared/modules/report/dtos/index/index.report.dto';
import { KktReportDto } from '@zaoblako/shared/modules/report/dtos/kkt/kkt.report.dto';
import { KPReportDto } from '@zaoblako/shared/modules/report/dtos/kp/kp.report.dto';
import { KPReportQueryDto } from '@zaoblako/shared/modules/report/dtos/kp/kp.report.query.dto';
import { NewContractReportDto } from '@zaoblako/shared/modules/report/dtos/new-contract/new.contract.report.dto';
import { NewContractReportQueryDto } from '@zaoblako/shared/modules/report/dtos/new-contract/new.contract.report.query.dto';
import { PaymentCalendarReportDto } from '@zaoblako/shared/modules/report/dtos/payment-calendar/payment.calendar.report.dto';
import { PaymentCalendarReportQueryDto } from '@zaoblako/shared/modules/report/dtos/payment-calendar/payment.calendar.report.query.dto';
import { PaymentAndOutgoingReportDto } from '@zaoblako/shared/modules/report/dtos/payment/payment.and.outgoing.report.dto';
import { PaymentAndOutgoingReportQueryDto } from '@zaoblako/shared/modules/report/dtos/payment/payment.and.outgoing.report.query.dto';
import { PotentialRentersReportDto } from '@zaoblako/shared/modules/report/dtos/potential-renters/potential.renters.report.dto';
import { PotentialRentersReportQueryDto } from '@zaoblako/shared/modules/report/dtos/potential-renters/potential.renters.report.query.dto';
import { ProblemRoomReportDto } from '@zaoblako/shared/modules/report/dtos/problem-room/problem.room.report.dto';
import { ProblemRoomReportQueryDto } from '@zaoblako/shared/modules/report/dtos/problem-room/problem.room.report.query.dto';
import { RentsReportDto } from '@zaoblako/shared/modules/report/dtos/rents/rents.report.dto';
import { RentsReportQueryDto } from '@zaoblako/shared/modules/report/dtos/rents/rents.report.query.dto';
import { RevenueShareReportQueryDto } from '@zaoblako/shared/modules/report/dtos/revenue-share/revenue.share.report.query.dto';
import { RevenueShareReportResultDto } from '@zaoblako/shared/modules/report/dtos/revenue-share/revenue.share.report.result.dto';
import { RevenueStructureReportQueryDto } from '@zaoblako/shared/modules/report/dtos/revenue-structure/revenue.structure.report.query.dto';
import { RevenueStructureReportResultDto } from '@zaoblako/shared/modules/report/dtos/revenue-structure/revenue.structure.report.result.dto';
import { NewRevenueReportQueryDto } from '@zaoblako/shared/modules/report/dtos/revenue/new/new.revenue.report.query.dto';
import { NewRevenueReportResultDto } from '@zaoblako/shared/modules/report/dtos/revenue/new/new.revenue.report.result.dto';
import { RevenueUserReportDto } from '@zaoblako/shared/modules/report/dtos/revenue/revenue.user.report.dto';
import { RevenueUserReportQueryDto } from '@zaoblako/shared/modules/report/dtos/revenue/revenue.user.report.query.dto';
import { RevenueWidgetInfoDto } from '@zaoblako/shared/modules/report/dtos/revenue/revenue.widget.info.dto';
import { RevenueWidgetReportQueryDto } from '@zaoblako/shared/modules/report/dtos/revenue/revenue.widget.report.query.dto';
import { RevenueYearReportDto } from '@zaoblako/shared/modules/report/dtos/revenue/year/revenue.year.report.dto';
import { RevenueYearReportQueryDto } from '@zaoblako/shared/modules/report/dtos/revenue/year/revenue.year.report.query.dto';
import { TaskStepsCountDto } from '@zaoblako/shared/modules/report/dtos/task-steps/task.steps.count.dto';
import { TaskReportDto } from '@zaoblako/shared/modules/report/dtos/task/task.report.dto';
import { TermsReportDto } from '@zaoblako/shared/modules/report/dtos/terms/terms.report.dto';
import { TermsReportQueryDto } from '@zaoblako/shared/modules/report/dtos/terms/terms.report.query.dto';
import { TrafficReportDto } from '@zaoblako/shared/modules/report/dtos/traffic/traffic.report.dto';
import { TrafficReportQueryDto } from '@zaoblako/shared/modules/report/dtos/traffic/traffic.report.query.dto';
import { TrafficWidgetInfoDto } from '@zaoblako/shared/modules/report/dtos/traffic/traffic.widget.info.dto';
import { TrafficWidgetReportQueryDto } from '@zaoblako/shared/modules/report/dtos/traffic/traffic.widget.report.query.dto';
import { FileFindDto } from '@zaoblako/shared/modules/storage/dtos/file.find.dto';
import { TaskCommentFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.comment.find.dto';
import { TaskHistoryFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.history.find.dto';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';

@Injectable({ providedIn: 'root' })
export class ReportService extends BaseService {
  protected baseUrl = '/report';

  public dashboardStatWidgets(): Observable<IndexReportDto> {
    return this.http.get<IndexReportDto>(`${this.baseUrl}`);
  }

  public dashboardTaskWidgets(): Observable<TaskReportDto> {
    return this.http.get<TaskReportDto>(`${this.baseUrl}/task`);
  }

  public dashboardTaskHistory(): Observable<TaskHistoryFindDto[]> {
    return this.http.get<TaskHistoryFindDto[]>(`/task-history`);
  }

  public dashboardRecentComments(): Observable<TaskCommentFindDto[]> {
    return this.http.get<TaskCommentFindDto[]>(`/task-comment`);
  }

  public receipt(queryParams: DocumentReportQueryDto): Observable<DocumentReportDto> {
    return this.http.get<DocumentReportDto>(`${this.baseUrl}/receipt`, { params: queryParams as any });
  }

  public outgoing(queryParams: DocumentReportQueryDto): Observable<DocumentReportDto> {
    return this.http.get<DocumentReportDto>(`${this.baseUrl}/outgoing`, { params: queryParams as any });
  }

  public sale(queryParams: DocumentReportQueryDto): Observable<DocumentReportDto> {
    return this.http.get<DocumentReportDto>(`${this.baseUrl}/sale`, { params: queryParams as any });
  }

  public payment(queryParams: DocumentReportQueryDto): Observable<DocumentReportDto> {
    return this.http.get<DocumentReportDto>(`${this.baseUrl}/payment`, { params: queryParams as any });
  }

  public contractService(queryParams: ContractServiceReportQueryDto): Observable<ContractServiceReportDto> {
    return this.http.get<ContractServiceReportDto>(`${this.baseUrl}/contract-service`, { params: queryParams as any });
  }

  public traffic(queryParams: TrafficReportQueryDto): Observable<TrafficReportDto> {
    return this.http.get<TrafficReportDto>(`${this.baseUrl}/traffic`, { params: queryParams as any });
  }

  public terms(queryParams: TermsReportQueryDto): Observable<TermsReportDto> {
    return this.http.get<TermsReportDto>(`${this.baseUrl}/terms`, { params: queryParams as any });
  }

  public areaOccupation(queryParams: AreaOccupationReportQueryDto): Observable<AreaOccupationReportDto> {
    return this.http.get<AreaOccupationReportDto>(`${this.baseUrl}/area-occupation`, { params: queryParams as any });
  }

  public revenue(queryParams: NewRevenueReportQueryDto): Observable<NewRevenueReportResultDto> {
    return this.http.get<NewRevenueReportResultDto>(`${this.baseUrl}/revenue`, { params: queryParams as any });
  }

  public rents(queryParams: RentsReportQueryDto): Observable<RentsReportDto> {
    return this.http.get<RentsReportDto>(`${this.baseUrl}/rents`, { params: queryParams as any });
  }

  public revenueYear(queryParams: RevenueYearReportQueryDto): Observable<RevenueYearReportDto> {
    return this.http.get<RevenueYearReportDto>(`${this.baseUrl}/revenue/year`, { params: queryParams as any });
  }

  public debt(queryParams: DebtReportQueryDto): Observable<DebtReportDto> {
    return this.http.get<DebtReportDto>(`${this.baseUrl}/debt`, { params: queryParams as any });
  }

  public autocheck(queryParams: AutocheckReportQueryDto): Observable<AutocheckReportDto> {
    return this.http.get<AutocheckReportDto>(`/1c/autocheck`, { params: queryParams as any });
  }

  public paymentOutgoing(queryParams: PaymentAndOutgoingReportQueryDto): Observable<PaymentAndOutgoingReportDto> {
    return this.http.get<PaymentAndOutgoingReportDto>(`${this.baseUrl}/payment-outgoing`, {
      params: queryParams as any
    });
  }

  public balance(queryParams: BalanceReportQueryDto): Observable<BalanceReportDto> {
    return this.http.get<BalanceReportDto>(`${this.baseUrl}/balance`, { params: queryParams as any });
  }

  public paymentCalendar(queryParams: PaymentCalendarReportQueryDto): Observable<PaymentCalendarReportDto> {
    return this.http.get<PaymentCalendarReportDto>(`${this.baseUrl}/payment-calendar`, { params: queryParams as any });
  }

  public taskStepsCount(): Observable<TaskStepsCountDto> {
    return this.http.get<TaskStepsCountDto>(`${this.baseUrl}/task-step-count`);
  }

  public widgetTrafficManager(queryParams: TrafficWidgetReportQueryDto): Observable<TrafficWidgetInfoDto> {
    return this.http.get<TrafficWidgetInfoDto>(`${this.baseUrl}/traffic/widget`, { params: queryParams as any });
  }

  public widgetRevenueManager(queryParams: RevenueWidgetReportQueryDto): Observable<RevenueWidgetInfoDto> {
    return this.http.get<RevenueWidgetInfoDto>(`${this.baseUrl}/revenue/widget`, { params: queryParams as any });
  }

  public trafficManager(queryParams: TrafficReportQueryDto): Observable<TrafficReportDto> {
    return this.http.get<TrafficReportDto>(`${this.baseUrl}/traffic/user`, { params: queryParams as any });
  }

  public revenueManager(queryParams: RevenueUserReportQueryDto): Observable<RevenueUserReportDto> {
    return this.http.get<RevenueUserReportDto>(`${this.baseUrl}/revenue/user`, { params: queryParams as any });
  }

  public kktManager(): Observable<KktReportDto[]> {
    return this.http.get<KktReportDto[]>(`${this.baseUrl}/kkt`);
  }

  public potential(queryParams: PotentialRentersReportQueryDto): Observable<PotentialRentersReportDto> {
    return this.http.get<PotentialRentersReportDto>(`${this.baseUrl}/potential`, { params: queryParams as any });
  }

  public taskDiscount(queryParams: DiscountReportQueryDto): Observable<DiscountReportDto> {
    return this.http.get<DiscountReportDto>(`${this.baseUrl}/discount`, { params: queryParams as any });
  }

  public newContract(queryParams: NewContractReportQueryDto): Observable<NewContractReportDto> {
    return this.http.get<NewContractReportDto>(`${this.baseUrl}/new-contract`, { params: queryParams as any });
  }

  public deal(queryParams: KPReportQueryDto): Observable<KPReportDto> {
    return this.http.get<KPReportDto>(`${this.baseUrl}/kp`, { params: queryParams as any });
  }

  public problemRoom(queryParams: ProblemRoomReportQueryDto): Observable<ProblemRoomReportDto> {
    return this.http.get<ProblemRoomReportDto>(`${this.baseUrl}/problem-room`, { params: queryParams as any });
  }

  public generateReport(): Observable<FileFindDto> {
    return this.http.get<FileFindDto>(`${this.baseUrl}/bank`);
  }

  public contractDocument(queryParams: ContractDocumentReportQueryDto): Observable<ContractDocumentReportDto> {
    return this.http.get<ContractDocumentReportDto>(`${this.baseUrl}/contract-document`, {
      params: queryParams as any
    });
  }

  public revenueShare(queryParams: RevenueShareReportQueryDto): Observable<RevenueShareReportResultDto> {
    return this.http.get<RevenueShareReportResultDto>(`${this.baseUrl}/revenue-share`, { params: queryParams as any });
  }

  public revenueShareXlsx(queryParams: RevenueShareReportQueryDto): Observable<FileFindDto> {
    return this.http.get<FileFindDto>(`${this.baseUrl}/revenue-share/xlsx`, {
      params: queryParams as any
    });
  }

  public revenueStructure(queryParams: RevenueStructureReportQueryDto): Observable<RevenueStructureReportResultDto> {
    return this.http.get<RevenueStructureReportResultDto>(`${this.baseUrl}/revenue-structure`, {
      params: queryParams as any
    });
  }
}
