import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contractIndexClass'
})

export class ContractIndexClassPipe implements PipeTransform {
  public transform(value: number, indexName: 'GMI' | 'HI' | 'RI'): any {
    switch (indexName) {
      case 'GMI':
        return {
          'bg-light-green': value !== null && value >= 100,
          'bg-light-red': value !== null && value < 100
        };
      case 'HI':
        return {
          'bg-light-green': value !== null && value <= 30,
          'bg-light-red': value !== null && value > 30
        };
      case 'RI':
        return {
          'bg-light-green': value !== null && value >= 100,
          'bg-light-red': value !== null && value < 100
        };
    }
  }
}
