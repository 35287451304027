import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { DashboardComponent } from '@zaoblako/web/modules/dashboard/components/dashboard/dashboard.component';
import { DashboardReportsComponent } from '@zaoblako/web/modules/dashboard/dumbs/reports/dashboard-reports.component';
import { SharedModule } from '@zaoblako/web/shared/shared.module';
import { MenuModule } from 'primeng/menu';
import { TabViewModule } from 'primeng/tabview';
import { DashboardClientIndexesComponent } from './dumbs/client-indexes/dashboard-client-indexes.component';
import { DashboardExpiringContractsComponent } from './dumbs/expiring-contracts/dashboard-expiring-contracts.component';
import { DashboardRecentCommentsComponent } from './dumbs/recent-comments/dashboard-recent-comments.component';
import { DashboardStatWidgetCardComponent } from './dumbs/stat-widget-card/dashboard-stat-widget-card.component';
import { DashboardStatsWidgetsComponent } from './dumbs/stats-widgets/dashboard-stats-widgets.component';
import { DashboardTaskStatsComponent } from './dumbs/task-stats/dashboard-task-stats.component';
import { DashboardTaskStepsComponent } from './dumbs/task-steps/dashboard-task-steps.component';

const dashboardRoutes: Route[] = [
  {
    path: '',
    component: DashboardComponent,
    data: {
      title: 'Рабочий стол - АТРИУМ CRM'
    }
  }
];

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardStatWidgetCardComponent,
    DashboardStatsWidgetsComponent,
    DashboardTaskStatsComponent,
    DashboardReportsComponent,
    DashboardRecentCommentsComponent,
    DashboardExpiringContractsComponent,
    DashboardTaskStepsComponent,
    DashboardClientIndexesComponent
  ],
  imports: [
    RouterModule.forChild(dashboardRoutes),
    SharedModule,
    MenuModule,
    TabViewModule,
    CommonModule
  ],
  providers: [],
  exports: [
    DashboardStatWidgetCardComponent
  ],
  bootstrap: []
})
export class DashboardModule {
}
