export enum TaskStatusTypeEnum {
  /**
   * Новая
   */
  TASK_STATUS_TYPE_OPENED = 'OPENED',
  /**
   * Принято в работу
   */
  TASK_STATUS_TYPE_ACCEPTED = 'ACCEPTED',
  /**
   * Завершена
   */
  TASK_STATUS_TYPE_COMPLETED = 'COMPLETED',
  /**
   * Снята / Удалена
   */
  TASK_STATUS_TYPE_CANCELED = 'CANCELED',

  /**
   * Ниже приведены типы статусов, которые являются собирательными
   */

  /**
   * Объединяет все статусы
   */
  TASK_STATUS_ALL = 'ALL',
  /**
   * Объединяет ACCEPTED и OPENED
   */
  TASK_STATUS_ACTIVE = 'ACTIVE',
  /**
   * Объединяет CANCELED и COMPLETED
   */
  TASK_STATUS_ARCHIVE = 'ARCHIVE',
  /**
   * Объединяет ACCEPTED и OPENED
   */
  TASK_STATUS_CURRENT = 'CURRENT',
}
