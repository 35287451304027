import { Component } from '@angular/core';
import { RoutingService } from '@zaoblako/web/core/services/routing.service';

@Component({
  selector: 'crm-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {

  public constructor(private readonly routingService: RoutingService) {
  }

  public redirectBack() {
    this.routingService.goToPreviousUrl();
  }

  public redirectToHomepage() {
    this.routingService.redirectToLk();
  }
}
