import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { RoleEnum } from '@zaoblako/shared/modules/security/enums/role.enum';
import { AuthService } from '@zaoblako/web/core/services/security/auth.service';
import { filter } from 'rxjs/operators';

const roleRoutes: Record<RoleEnum, string> = {
  [RoleEnum.ROLE_ACCOUNTANT]: '/',
  [RoleEnum.ROLE_ADMIN]: '/',
  [RoleEnum.ROLE_ARCHITECT]: '/',
  [RoleEnum.ROLE_CHIEF_ME]: '/',
  [RoleEnum.ROLE_DEPUTY_HEAD]: '/',
  [RoleEnum.ROLE_DIRECTOR]: '/',
  [RoleEnum.ROLE_EMPLOYEE]: '/',
  [RoleEnum.ROLE_FREELANCE]: '/',
  [RoleEnum.ROLE_HEAD_DEPARTMENT]: '/',
  [RoleEnum.ROLE_HEAD_ENGINEERING]: '/',
  [RoleEnum.ROLE_HEAD_OPERATION]: '/',
  [RoleEnum.ROLE_HEAD_PE]: '/',
  [RoleEnum.ROLE_HEAD_SECURITY]: '/',
  [RoleEnum.ROLE_INVESTOR]: '/',
  [RoleEnum.ROLE_LAWYER]: '/',
  [RoleEnum.ROLE_RENTAL_MANAGER]: '/',
  [RoleEnum.ROLE_SECURITY]: '/security',
  [RoleEnum.ROLE_SYS_INTEGRATION]: '/',
  [RoleEnum.ROLE_SYS_MANAGER]: '/',
  [RoleEnum.ROLE_SYS_SUPPORT]: '/',
  [RoleEnum.ROLE_COOWNER]: '/coowner',
  [RoleEnum.ROLE_OPERATION]: '/operation',
  [RoleEnum.ROLE_USER_DIRECTOR]: '/manager',
  [RoleEnum.ROLE_USER_MANAGER]: '/manager',
  [RoleEnum.ROLE_USER]: '/manager',
  [RoleEnum.ROLE_MEETING]: '/'
};

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  public currentUrl = '/';
  public previousUrl = '/';

  public constructor(private readonly router: Router,
                     private readonly titleService: Title,
                     private readonly authService: AuthService) {
    this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          if (event.url !== '/notfound') {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
          }

          if (event.url === '/') {
            this.redirectToLk();
          }
        });

    this.router.events.subscribe(data => {
      if (data instanceof ActivationEnd && !data.snapshot.routeConfig.children && !data.snapshot.routeConfig.loadChildren) {
        this.titleService.setTitle(data.snapshot.data.title || 'АТРИУМ CRM');
      }
    });
  }

  public goToPreviousUrl() {
    if (this.previousUrl === '/') {
      this.redirectToLk();
    } else {
      this.router.navigateByUrl(this.previousUrl).catch(console.error);
    }
  }

  public redirectToLk() {
    const path = roleRoutes[this.authService.currentUser.roles[0]] || '/login';
    this.router.navigate([path]).catch(console.error);
  }

  public redirectToNotFound() {
    this.router.navigate(['notfound']).catch(console.error);
  }
}
