export enum AccessEnum {
  /**
   * Чтение
   */
  READ = 'READ',
  /**
   * Запись
   */
  WRITE = 'WRITE'
}
