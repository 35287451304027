import { Injectable } from '@angular/core';
import { AreaOccupationReportQueryDto } from '@zaoblako/shared/modules/report/dtos/area/area.occupation.report.query.dto';
import { AutocheckReportQueryDto } from '@zaoblako/shared/modules/report/dtos/autocheck/autocheck.report.query.dto';
import { BalanceReportQueryDto } from '@zaoblako/shared/modules/report/dtos/balance/balance.report.query.dto';
import { ContractDocumentReportDto } from '@zaoblako/shared/modules/report/dtos/contract-document/contract.document.report.dto';
import { ContractDocumentReportQueryDto } from '@zaoblako/shared/modules/report/dtos/contract-document/contract.document.report.query.dto';
import { ContractServiceReportQueryDto } from '@zaoblako/shared/modules/report/dtos/contract/contract.service.report.query.dto';
import { DebtReportQueryDto } from '@zaoblako/shared/modules/report/dtos/debt/debt.report.query.dto';
import { DiscountReportQueryDto } from '@zaoblako/shared/modules/report/dtos/discount/discount.report.query.dto';
import { DocumentReportQueryDto } from '@zaoblako/shared/modules/report/dtos/document/document.report.query.dto';
import { KPReportDto } from '@zaoblako/shared/modules/report/dtos/kp/kp.report.dto';
import { KPReportQueryDto } from '@zaoblako/shared/modules/report/dtos/kp/kp.report.query.dto';
import { NewContractReportDto } from '@zaoblako/shared/modules/report/dtos/new-contract/new.contract.report.dto';
import { NewContractReportQueryDto } from '@zaoblako/shared/modules/report/dtos/new-contract/new.contract.report.query.dto';
import { PaymentCalendarReportQueryDto } from '@zaoblako/shared/modules/report/dtos/payment-calendar/payment.calendar.report.query.dto';
import { PaymentAndOutgoingReportQueryDto } from '@zaoblako/shared/modules/report/dtos/payment/payment.and.outgoing.report.query.dto';
import { PotentialRentersReportDto } from '@zaoblako/shared/modules/report/dtos/potential-renters/potential.renters.report.dto';
import { PotentialRentersReportQueryDto } from '@zaoblako/shared/modules/report/dtos/potential-renters/potential.renters.report.query.dto';
import { ProblemRoomReportQueryDto } from '@zaoblako/shared/modules/report/dtos/problem-room/problem.room.report.query.dto';
import { RentsReportQueryDto } from '@zaoblako/shared/modules/report/dtos/rents/rents.report.query.dto';
import { RevenueShareReportQueryDto } from '@zaoblako/shared/modules/report/dtos/revenue-share/revenue.share.report.query.dto';
import { RevenueStructureReportQueryDto } from '@zaoblako/shared/modules/report/dtos/revenue-structure/revenue.structure.report.query.dto';
import { RevenueStructureReportResultDto } from '@zaoblako/shared/modules/report/dtos/revenue-structure/revenue.structure.report.result.dto';
import { NewRevenueReportQueryDto } from '@zaoblako/shared/modules/report/dtos/revenue/new/new.revenue.report.query.dto';
import { RevenueUserReportQueryDto } from '@zaoblako/shared/modules/report/dtos/revenue/revenue.user.report.query.dto';
import { RevenueWidgetReportQueryDto } from '@zaoblako/shared/modules/report/dtos/revenue/revenue.widget.report.query.dto';
import { RevenueYearReportQueryDto } from '@zaoblako/shared/modules/report/dtos/revenue/year/revenue.year.report.query.dto';
import { TermsReportQueryDto } from '@zaoblako/shared/modules/report/dtos/terms/terms.report.query.dto';
import { TrafficReportQueryDto } from '@zaoblako/shared/modules/report/dtos/traffic/traffic.report.query.dto';
import { TrafficWidgetReportQueryDto } from '@zaoblako/shared/modules/report/dtos/traffic/traffic.widget.report.query.dto';
import { FileFindDto } from '@zaoblako/shared/modules/storage/dtos/file.find.dto';
import { ReportService } from '@zaoblako/web/core/services/report/report.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportStateService {
  public constructor(private readonly reportService: ReportService) {}

  public dashboardStatWidgets() {
    return this.reportService.dashboardStatWidgets();
  }

  public dashboardTaskWidgets() {
    return this.reportService.dashboardTaskWidgets();
  }

  public dashboardTaskHistory() {
    return this.reportService.dashboardTaskHistory();
  }

  public dashboardRecentComments() {
    return this.reportService.dashboardRecentComments();
  }

  public receipt(queryParams: DocumentReportQueryDto) {
    return this.reportService.receipt(queryParams);
  }

  public outgoing(queryParams: DocumentReportQueryDto) {
    return this.reportService.outgoing(queryParams);
  }

  public sale(queryParams: DocumentReportQueryDto) {
    return this.reportService.sale(queryParams);
  }

  public payment(queryParams: DocumentReportQueryDto) {
    return this.reportService.payment(queryParams);
  }

  public contractService(queryParams: ContractServiceReportQueryDto) {
    return this.reportService.contractService(queryParams);
  }

  public traffic(queryParams: TrafficReportQueryDto) {
    return this.reportService.traffic(queryParams);
  }

  public terms(queryParams: TermsReportQueryDto) {
    return this.reportService.terms(queryParams);
  }

  public areaOccupation(queryParams: AreaOccupationReportQueryDto) {
    return this.reportService.areaOccupation(queryParams);
  }

  public revenue(queryParams: NewRevenueReportQueryDto) {
    return this.reportService.revenue(queryParams);
  }

  public rents(queryParams: RentsReportQueryDto) {
    return this.reportService.rents(queryParams);
  }

  public revenueYear(queryParams: RevenueYearReportQueryDto) {
    return this.reportService.revenueYear(queryParams);
  }

  public debt(queryParams: DebtReportQueryDto) {
    return this.reportService.debt(queryParams);
  }

  public autocheck(queryParams: AutocheckReportQueryDto) {
    return this.reportService.autocheck(queryParams);
  }

  public paymentOutgoing(queryParams: PaymentAndOutgoingReportQueryDto) {
    return this.reportService.paymentOutgoing(queryParams);
  }

  public balance(queryParams: BalanceReportQueryDto) {
    return this.reportService.balance(queryParams);
  }

  public paymentCalendar(queryParams: PaymentCalendarReportQueryDto) {
    return this.reportService.paymentCalendar(queryParams);
  }

  public taskStepsCount() {
    return this.reportService.taskStepsCount();
  }

  public widgetTrafficManager(queryParams: TrafficWidgetReportQueryDto) {
    return this.reportService.widgetTrafficManager(queryParams);
  }

  public widgetRevenueManager(queryParams: RevenueWidgetReportQueryDto) {
    return this.reportService.widgetRevenueManager(queryParams);
  }

  public trafficManager(queryParams: TrafficReportQueryDto) {
    return this.reportService.trafficManager(queryParams);
  }

  public revenueManager(queryParams: RevenueUserReportQueryDto) {
    return this.reportService.revenueManager(queryParams);
  }

  public kktManager() {
    return this.reportService.kktManager();
  }

  public potential(queryParams: PotentialRentersReportQueryDto): Observable<PotentialRentersReportDto> {
    return this.reportService.potential(queryParams);
  }

  public taskDiscount(queryParams: DiscountReportQueryDto) {
    return this.reportService.taskDiscount(queryParams);
  }

  public newContract(queryParams: NewContractReportQueryDto): Observable<NewContractReportDto> {
    return this.reportService.newContract(queryParams);
  }

  public deal(queryParams: KPReportQueryDto): Observable<KPReportDto> {
    return this.reportService.deal(queryParams);
  }

  public problemRoom(queryParams: ProblemRoomReportQueryDto) {
    return this.reportService.problemRoom(queryParams);
  }

  public generateReport(): Observable<FileFindDto> {
    return this.reportService.generateReport();
  }

  public contractDocument(queryParams: ContractDocumentReportQueryDto): Observable<ContractDocumentReportDto> {
    return this.reportService.contractDocument(queryParams);
  }

  public revenueShare(queryParams: RevenueShareReportQueryDto) {
    return this.reportService.revenueShare(queryParams);
  }

  public revenueShareXlsx(queryParams: RevenueShareReportQueryDto) {
    return this.reportService.revenueShareXlsx(queryParams);
  }

  public revenueStructure(queryParams: RevenueStructureReportQueryDto): Observable<RevenueStructureReportResultDto> {
    return this.reportService.revenueStructure(queryParams);
  }
}
