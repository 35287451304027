export enum RoleEnum {
  ROLE_SYS_SUPPORT = 'SYS_SUPPORT',
  ROLE_SYS_INTEGRATION = 'SYS_INTEGRATION',
  ROLE_DIRECTOR = 'DIRECTOR',
  ROLE_ACCOUNTANT = 'ACCOUNTANT',
  ROLE_HEAD_ENGINEERING = 'HEAD_ENGINEERING',
  ROLE_HEAD_PE = 'HEAD_PE',
  ROLE_ARCHITECT = 'ARCHITECT',
  ROLE_HEAD_DEPARTMENT = 'HEAD_DEPARTMENT',
  ROLE_EMPLOYEE = 'EMPLOYEE',
  ROLE_USER_DIRECTOR = 'USER_DIRECTOR',
  ROLE_SYS_MANAGER = 'SYS_MANAGER',
  ROLE_INVESTOR = 'INVESTOR',
  ROLE_RENTAL_MANAGER = 'ROLE_RENTAL_MANAGER',
  ROLE_LAWYER = 'LAWYER',
  ROLE_HEAD_OPERATION = 'HEAD_OPERATION',
  ROLE_CHIEF_ME = 'CHIEF_ME',
  ROLE_HEAD_SECURITY = 'HEAD_SECURITY',
  ROLE_DEPUTY_HEAD = 'DEPUTY_HEAD',
  ROLE_FREELANCE = 'FREELANCE',
  ROLE_USER_MANAGER = 'USER_MANAGER',
  ROLE_SECURITY = 'SECURITY',
  ROLE_COOWNER = 'COOWNER',
  ROLE_OPERATION = 'OPERATION',
  ROLE_ADMIN = 'ADMIN',
  ROLE_USER = 'USER',
  ROLE_MEETING = 'MEETING'
}
