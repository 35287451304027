<p-card styleClass="h-full overflow-hidden">
  <h3 class="mt-2 mb-0">Этапы сделок по аренде</h3>
  <p-divider></p-divider>
  <div class="relative">
    <div class="funnel-chart-labels">
      <div *ngFor="let step of taskSteps; last as isLast" class="funnel-chart-labels__item">
        <a [routerLink]="step.routerLink" [queryParams]="step.queryParams">
          <p class="text-2xl">{{step.value}}</p>
          <p>{{step.name}}</p>
        </a>
      </div>
    </div>
    <div class="mt-4 pointer-events-none flex flex-column align-items-center">
      <div class="trapezoid" *ngFor="let step of taskSteps; index as index" [ngStyle]="getStepStyles(index, step.color)"></div>
    </div>
  </div>
</p-card>
