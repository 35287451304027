import { Pipe, PipeTransform } from '@angular/core';
import { ActivityFeedItemDto } from '@zaoblako/shared/modules/notification/dtos/activity.feed.item.dto';
import { UnreadNotificationItemDto } from '@zaoblako/shared/modules/notification/dtos/unread.notification.item.dto';
import { NotificationSocketInterface } from '@zaoblako/shared/modules/notification/interfaces/notification.socket.interface';
import { PeriodEnum } from '@zaoblako/shared/modules/report/enums/period.enum';
import { NotificationGroupEnum } from '@zaoblako/shared/modules/security/enums/notification.group.enum';
import { RoleEnum } from '@zaoblako/shared/modules/security/enums/role.enum';
import { AuthService } from '@zaoblako/web/core/services/security/auth.service';
import * as moment from 'moment-timezone';

const urlNotificationGroup: Record<NotificationGroupEnum, string> = {
  [NotificationGroupEnum.ROOM]: '/location/room/card/',
  [NotificationGroupEnum.CONTRACT]: '/contract/card/',
  [NotificationGroupEnum.TASK]: '/task/card/',
  [NotificationGroupEnum.CLIENT]: '/client/card/',
  [NotificationGroupEnum.OFD_CONTROL]: '/report/revenue'
};

@Pipe({
  name: 'convertNotificationToLink'
})
export class ConvertNotificationToLinkPipe implements PipeTransform {
  public constructor(private readonly authService: AuthService) {}

  public transform(
    notification: UnreadNotificationItemDto | NotificationSocketInterface | ActivityFeedItemDto,
    isSetQueryParams = false
  ): any {
    if (this.authService.hasRole(RoleEnum.ROLE_ADMIN)) {
      if (notification.group !== NotificationGroupEnum.OFD_CONTROL) {
        return !isSetQueryParams ? urlNotificationGroup[notification.group] + notification.entity?.id : {};
      } else {
        if (isSetQueryParams) {
          let queryParams;
          queryParams = {
            type: PeriodEnum.DAY
          };
          if (notification.entity?.name === 'Нет выручки') {
            queryParams.from = moment((notification as UnreadNotificationItemDto).created)
              .startOf('month')
              .format('YYYY-MM-DD');
            queryParams.to = moment((notification as UnreadNotificationItemDto).created)
              .endOf('month')
              .format('YYYY-MM-DD');
            queryParams.roomIds = notification.entity?.id;
          } else {
            queryParams.from = moment((notification as UnreadNotificationItemDto).created)
              .subtract(1, 'day')
              .startOf('day')
              .format('YYYY-MM-DD');
            queryParams.to = moment((notification as UnreadNotificationItemDto).created)
              .subtract(1, 'day')
              .endOf('day')
              .format('YYYY-MM-DD');
          }
          return queryParams;
        } else {
          return urlNotificationGroup[notification.group];
        }
      }
    } else if (this.authService.hasRole(RoleEnum.ROLE_COOWNER)) {
      return !isSetQueryParams ? '/coowner' + urlNotificationGroup[notification.group] + notification.entity?.id : {};
    } else if (this.authService.hasRole(RoleEnum.ROLE_OPERATION)) {
      return !isSetQueryParams ? '/operation' + urlNotificationGroup[notification.group] + notification.entity?.id : {};
    } else if (
      this.authService.hasRole([RoleEnum.ROLE_USER_DIRECTOR, RoleEnum.ROLE_USER_MANAGER, RoleEnum.ROLE_USER])
    ) {
      return !isSetQueryParams ? '/manager' + urlNotificationGroup[notification.group] + notification.entity?.id : {};
    } else if (this.authService.hasRole(RoleEnum.ROLE_SECURITY)) {
      return !isSetQueryParams ? '/security' + urlNotificationGroup[notification.group] + notification.entity?.id : {};
    }
    return !isSetQueryParams ? urlNotificationGroup[notification.group] + notification.entity?.id : {};
  }
}
