import { Component, Input } from '@angular/core';
import { ClientIndexReportDto } from '@zaoblako/shared/modules/report/dtos/client/client.index.report.dto';
import { CrmTableColumn } from '@zaoblako/web/core/models/crm-table-column.interface';

@Component({
  selector: 'crm-dashboard-client-indexes',
  templateUrl: './dashboard-client-indexes.component.html'
})
export class DashboardClientIndexesComponent {

  @Input() public clientIndexes: ClientIndexReportDto[];

  public clientTableColumns: CrmTableColumn[] = [
    { label: 'Клиент', name: 'client', sort: 'client.name:object:string' },
    { label: 'GMI', name: 'GMI', sort: 'GMI:number', style: { 'width.px': 80 } },
    { label: 'HI', name: 'HI', sort: 'HI:number', style: { 'width.px': 80 } },
    { label: 'RI', name: 'RI', sort: 'RI:number', style: { 'width.px': 80 } }
  ];

  public toClientIndex(clientIndex: any) {
    return clientIndex as ClientIndexReportDto;
  }

  public getClient(clientIndex: ClientIndexReportDto) {
    return clientIndex.room?.contract?.person?.client;
  }

}
