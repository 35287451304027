<p-card styleClass="h-full">
  <div class="flex flex-wrap justify-content-between align-items-start">
    <div>
      <a class="text-lg" [routerLink]="link" [queryParams]="withPeriodQueryParams ? ({} | concatDateParams:activePeriodName): null">{{title}}</a>
      <p class="greytext" *ngIf="description; else emptyBlock">{{description}}</p>
      <ng-template #emptyBlock>
        <div>&nbsp;</div>
      </ng-template>
    </div>
    <div class="text-2xl flex align-items-center">
      <ng-content></ng-content>
    </div>
  </div>

  <p class="text-right mt-2">
    <button
      pButton
      label="Подробнее..."
      class="p-button-text p-button-sm p-button-secondary"
      [routerLink]="link"
      [queryParams]="withPeriodQueryParams ? ({} | concatDateParams:activePeriodName): null">
    </button>
  </p>
</p-card>
