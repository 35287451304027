<div class="grid p-align-center grid-nogutter">
  <div class="lg:col-8 xl:col-9 sm:col-0 md:col-0 pb-0 grid-nogutter security-left-column hidden lg:inline-flex"></div>
  <div class="col ml-6 mr-6 pb-2 security-right-column">
    <div class="align-items-center text-center my-6">
      <img alt="Логотип АТРИУМ" src="/assets/images/logo.svg" height="220px">
    </div>

    <div *ngIf="!isReset" class="p-fluid" (keyup.enter)="loginClick()">
      <div class="field">
        <label>Логин</label>
        <span class="p-input-icon-left">
          <i class="pi pi-user"></i>
          <input [(ngModel)]="login.email" [ngClass]="{ 'ng-invalid ng-dirty': errorsLogin?.email }" pInputText type="email" name="email" autocomplete="autocomplete" />
        </span>
        <small *ngIf="errorsLogin?.email" class="p-error">{{ errorsLogin.email }}</small>
      </div>

      <div class="field">
        <label>Пароль</label>
        <span class="p-input-icon-left">
          <i class="pi pi-lock"></i>
          <input [(ngModel)]="login.password" [ngClass]="{ 'ng-invalid ng-dirty': errorsLogin?.password }" pInputText type="password" name="password" autocomplete="autocomplete" />
        </span>
        <small class="p-error" *ngIf="errorsLogin?.password">{{ errorsLogin.password }}</small>
      </div>

      <div class="field">
        <div class="field-checkbox mb-0">
          <p-checkbox [(ngModel)]="login.remember" [ngClass]="{ 'ng-invalid ng-dirty': errorsLogin?.remember }" [binary]="true" name="remember" [inputId]="'remember'"></p-checkbox>
          <label [for]="'remember'" class="ml-2 pointer">Запомнить пароль?</label>
        </div>
        <small class="p-error" *ngIf="errorsLogin?.remember">{{ errorsLogin.remember }}</small>
      </div>

      <div class="field">
        <div class="field-checkbox mb-0">
          <p-checkbox [(ngModel)]="terms.is" [ngClass]="{ 'ng-invalid ng-dirty': terms?.error }" [binary]="true" name="terms" [inputId]="'terms'"></p-checkbox>
          <label [for]="'terms'" class="ml-2 text-sm pointer">
            Я внимательно прочитал и принимаю условия <a target="_blank" routerLink="/license">пользовательского соглашения</a>
          </label>
        </div>
        <small class="p-error" *ngIf="terms?.error">{{ terms.error }}</small>
      </div>

      <div class="flex p-justify-end grid-nogutter mt-2">
        <button [loading]="loading" loadingIcon="pi pi-spinner pi-spin" pButton class="p-button" label="Войти" (click)="loginClick()"></button>
      </div>
    </div>

    <div *ngIf="isReset" class="p-fluid">
      <div *ngIf="!isSendResetEmail" class="field">
        <label>Email</label>
        <span class="p-input-icon-left">
          <i class="pi pi-user"></i>
          <input [(ngModel)]="resetEmail.email" [ngClass]="{ 'ng-invalid': errorsEmail?.email }" pInputText type="email" />
        </span>
        <small *ngIf="errorsEmail?.email" class="p-error">{{ errorsEmail.email }}</small>
      </div>

      <div *ngIf="isSendResetEmail">
        <p-messages [enableService]="false" severity="info">
          <ng-template pTemplate>
            <div class="text-center">
              <p><strong>Вам отправлено письмо с деталями смены пароля</strong></p>
              <p>На указанную при регистрации почту отправлена ссылка для смены пароля</p>
            </div>
          </ng-template>
        </p-messages>
      </div>

      <div class="flex p-justify-end grid-nogutter mt-2">
        <button *ngIf="!isSendResetEmail"
                [loading]="loading"
                loadingIcon="pi pi-spinner pi-spin"
                pButton class="p-button"
                label="Сменить пароль"
                (click)="resetClick()">
        </button>
      </div>
    </div>

    <div class="error-divider" *ngIf="errBack?.message">
      <p-messages [value]="[{ severity: 'warn', detail: this.errBack?.message }]" [enableService]="false"></p-messages>
    </div>
    <div class="flex justify-content-between mt-4">
      <a *ngIf="!isReset" class="pointer" routerLink="/register">Регистрация</a>
      <a class="pointer" (click)="change()">{{ isReset ? 'Вернуться на страницу входа' : 'Забыли пароль?' }}</a>
    </div>
    <div class="flex p-justify-end mt-6 copyright">
      <small>Все права защищены. ⓒ {{ footerYear }}</small>
    </div>

  </div>
</div>
