import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from '@zaoblako/web/core/services/error.service';
import { AuthService } from '@zaoblako/web/core/services/security/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public constructor(private authService: AuthService,
                     private errorService: ErrorService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        this.authService.logout();
      }

      const error = err.error.errors || { message: err.statusText };

      if (err.status === 0 && !navigator.onLine) {
        error.message = 'Не удалось установить связь с сервером. Проверьте Ваше интернет соединение.';
      }
      this.errorService.addDefaultError(error);

      return throwError(error);

    }));
  }
}
