import { Component, OnInit } from '@angular/core';
import { ClientRegistrationDto } from '@zaoblako/shared/modules/security/dtos/user/client.registration.dto';
import { RegistrationDto } from '@zaoblako/shared/modules/security/dtos/user/registration.dto';
import { TaskRegistrationDto } from '@zaoblako/shared/modules/security/dtos/user/task.registration.dto';
import { UserRegistrationDto } from '@zaoblako/shared/modules/security/dtos/user/user.registration.dto';
import { RegisterStateService } from '@zaoblako/web/core/services/security/register-state.service';
import { validate } from '@zaoblako/web/core/services/validation/validate.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'crm-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public register: RegistrationDto = new RegistrationDto();
  public visibleForm = true;

  public errorsClient: Record<keyof ClientRegistrationDto, any[]>;
  public errorsUser: Record<keyof UserRegistrationDto, any[]>;
  public errorsTask: Record<keyof TaskRegistrationDto, any[]>;

  public err: { valid: boolean, errors?: any }[] = [];
  public errBack: any;

  public terms: { is: boolean, error: string | undefined } = {
    is: false,
    error: undefined
  };

  public loading = false;

  public footerYear: string;

  public constructor(private readonly registerStateService: RegisterStateService) {
  }

  public ngOnInit(): void {
    this.register = {
      client: new ClientRegistrationDto(),
      user: new UserRegistrationDto(),
      task: new TaskRegistrationDto()
    };

    if (moment().year() === 2021) {
      this.footerYear = "2021";
    } else {
      this.footerYear = `2021-${moment().year()}`;
    }
  }

  public clickRegister() {
    this.loading = true;

    if (!this.terms.is) {
      this.loading = false;
      this.terms.error = 'Необходимо принять соглашение';
      return ;
    }

    this.terms.error = undefined;
    this.err = [];

    this.err.push(validate(this.register.client, ClientRegistrationDto));
    this.err.push(validate(this.register.user, UserRegistrationDto));
    this.err.push(validate(this.register.task, TaskRegistrationDto));
    if (this.err.find((er) => er?.valid === false)) {
      this.errorsClient = this.err[0].errors;
      this.errorsUser = this.err[1].errors;
      this.errorsTask = this.err[2].errors;
      console.log(this.errorsClient, this.errorsUser, this.errorsTask);
      this.loading = false;
    } else {
      this.errorsClient = null;
      this.errorsUser = null;
      this.errorsTask = null;

      this.registerStateService.register(this.register).subscribe({
        next: () => {
          this.loading = false;
          this.visibleForm = false;
        },
        error: (err) => {
          this.loading = false;
          this.errBack = err;
        }
      });
    }
  }

}
