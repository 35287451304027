import { BaseDto } from '@zaoblako/shared/common/dtos/base.dto';
import { Expose, Transform } from 'class-transformer';
import { IsBoolean, IsEmail, IsOptional, IsString, Length } from 'class-validator';

export class UserLoginDto extends BaseDto {

  @Expose()
  @Transform(element => element.value ? element.value.toLowerCase() : undefined)
  @IsEmail({}, {
    message: 'Неверный email адрес'
  })
  public email: string;

  @IsString({
    message: 'Введите пароль'
  })
  @Length(8, 100, { message: 'Пароль должен быть не менее $constraint1 символов' })
  public password: string;

  @IsOptional()
  @IsBoolean()
  public remember?: boolean;

}
