import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InformerTypeEnum } from '@zaoblako/shared/common/enums/informer.type.enum';
import { ActivityFeedQueryDto } from '@zaoblako/shared/modules/notification/dtos/activity.feed.query.dto';
import { ActivityFeedResultDto } from '@zaoblako/shared/modules/notification/dtos/activity.feed.result.dto';
import { UnreadNotificationItemDto } from '@zaoblako/shared/modules/notification/dtos/unread.notification.item.dto';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public unreadCount$: Subject<Record<InformerTypeEnum, number>> = new Subject();
  public eventReadNotification: Subject<InformerTypeEnum> = new Subject<InformerTypeEnum>();

  private baseUrl = '/notification';

  public constructor(private readonly http: HttpClient) {
  }

  public read(notificationId: number, informerType: InformerTypeEnum): Observable<null> {
    return this.http.put<null>(`${this.baseUrl}/${informerType}/${notificationId}`, {});
  }

  public unreadCount(): Observable<{ result: Record<InformerTypeEnum, number> }> {
    return this.http.get<{ result: Record<InformerTypeEnum, number> }>(`${this.baseUrl}/count-unread`);
  }

  public findUnreadByType(informerType: InformerTypeEnum): Observable<UnreadNotificationItemDto[]> {
    return this.http.get<UnreadNotificationItemDto[]>(`${this.baseUrl}/${informerType}/unread`);
  }

  public findAllFeed(queryParams: ActivityFeedQueryDto): Observable<ActivityFeedResultDto> {
    return this.http.get<ActivityFeedResultDto>(`${this.baseUrl}/feed`, { params: queryParams as any });
  }

  public readOne(notificationId: number, informerType: InformerTypeEnum): Observable<null> {
    return this.http.put<null>(`${this.baseUrl}/${informerType}/read-one/${notificationId}`, {});
  }

  public readAll(informerType: InformerTypeEnum): Observable<null> {
    return this.http.put<null>(`${this.baseUrl}/${informerType}/clear`, {});
  }

}
