import { Component, Input } from '@angular/core';
import { ContractExpiringContractReportDto } from '@zaoblako/shared/modules/report/dtos/contract/contract.expiring.contract.report.dto';
import { ContractExpiringReportDto } from '@zaoblako/shared/modules/report/dtos/contract/contract.expiring.report.dto';
import { CrmTableColumn } from '@zaoblako/web/core/models/crm-table-column.interface';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'crm-dashboard-expiring-contracts',
  templateUrl: './dashboard-expiring-contracts.component.html'
})
export class DashboardExpiringContractsComponent {

  @Input() public contracts: ContractExpiringReportDto;

  public contractMenuItems: MenuItem[] = [
    { label: 'Долгоср.', state: { name: 'long' }, command: event => this.setActiveContractMenuItem(event.item) },
    { label: 'Краткоср.', state: { name: 'short' }, command: event => this.setActiveContractMenuItem(event.item) }
  ];

  public activeContractMenuItem = this.contractMenuItems[0];

  public contractTableColumns: CrmTableColumn[] = [
    { label: 'Договор', name: 'contract', sort: 'number:string' },
    { label: 'Истекает через', name: 'days', sort: 'days:number' }
  ];

  private setActiveContractMenuItem(contractMenuItem: MenuItem) {
    this.activeContractMenuItem = contractMenuItem;
  }

  public toContract(contract: any) {
    return contract as ContractExpiringContractReportDto;
  }

}
