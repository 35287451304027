<div class="grid">
  <div class="col-12 lg:col-4">
    <p-card styleClass="h-full">
      <h3 class="mt-2 mb-0">
        Заявки
        <ng-container [ngSwitch]="currentUser?.id">
          <a *ngSwitchCase="27873" routerLink="/task/accepted" [queryParams]="{neededAgreement: true, templateIds: [260, 262, 264, 263, 251, 252], sort: 'created:date'}">Михаил
            Горшенин</a>
          <a *ngSwitchCase="27895" routerLink="/task/accepted" [queryParams]="{tagIds: 19, sort: 'created:date'}">Рудяк Эрнест</a>
          <a *ngSwitchCase="29460" routerLink="/task/accepted" [queryParams]="{tagIds: 18, sort: 'created:date'}">Чекрыжов Павел</a>
          <a *ngSwitchCase="27899" routerLink="/task/accepted" [queryParams]="{sort: 'created:date'}">Бурт-Яшин И. Я.</a>
        </ng-container>
      </h3>
      <p class="greytext">{{activePeriodLabel}}</p>
      <p-divider></p-divider>
      <p-table styleClass="borderless-table p-datatable-sm" [value]="[null]" [autoLayout]="true">
        <ng-template pTemplate="body">
          <ng-container *ngIf="taskStats; else taskStatsSkeleton">
            <tr *ngFor="let status of taskStats.all | orderBy:'order:number'">
              <td class="fw-bold">
                <a [routerLink]="status.type | taskListUrlByStatus" [queryParams]="{} | concatDateParams:activePeriodName">{{status.name}}</a>
              </td>
              <td class="fw-bold text-right">{{status.count | number}}</td>
            </tr>
          </ng-container>
        </ng-template>
      </p-table>
      <p-divider></p-divider>
      <div class="text-right">
        <button pButton
                label="Подробнее..."
                class="p-button-text
                p-button-secondary
                p-button-sm"
                routerLink="/task/active"
                [queryParams]="{} | concatDateParams:activePeriodName">
        </button>
      </div>
    </p-card>
  </div>
  <div class="col-12 lg:col-4">
    <p-card styleClass="h-full">
      <h3 class="mt-2 mb-0">Заявки на аренду</h3>
      <p class="greytext">{{activePeriodLabel}}</p>
      <p-divider></p-divider>
      <p-table styleClass="borderless-table p-datatable-sm" [value]="[null]" [autoLayout]="true">
        <ng-template pTemplate="body">
          <ng-container *ngIf="taskStats; else taskStatsSkeleton">
            <tr *ngFor="let status of taskStats.rent.data | orderBy:'order:number'">
              <td class="fw-bold">
                <a [routerLink]="status.type | taskListUrlByStatus"
                   [queryParams]="{templateIds: taskStats.rent.template.id} | concatDateParams:activePeriodName">
                  {{status.name}}
                </a>
              </td>
              <td class="fw-bold text-right">{{status.count | number}}</td>
            </tr>
          </ng-container>
        </ng-template>
      </p-table>
      <p-divider></p-divider>
      <div class="text-right">
        <button *ngIf="taskStats"
                pButton
                label="Подробнее..."
                class="p-button-text p-button-secondary p-button-sm"
                routerLink="/task/active"
                [queryParams]="{templateIds: taskStats.rent.template.id} | concatDateParams:activePeriodName">
        </button>
      </div>
    </p-card>
  </div>
  <div class="col-12 lg:col-4">
    <p-card styleClass="h-full">
      <h3 class="mt-2 mb-0">Для совещания</h3>
      <p class="greytext">{{activePeriodLabel}}, заявки на скидку готовые к рассмотрению</p>
      <p-divider></p-divider>
      <p-table styleClass="borderless-table p-datatable-sm" [value]="[null]" [autoLayout]="true">
        <ng-template pTemplate="body">
          <ng-container *ngIf="taskStats; else taskStatsSkeleton">
            <tr *ngFor="let status of taskStats.meeting">
              <td class="fw-bold">
                <a routerLink="/task/accepted" [queryParams]="{templateIds: status.template.id} | concatDateParams:activePeriodName">{{status.template.name}}</a>
              </td>
              <td class="fw-bold text-right">{{status.count | number}}</td>
            </tr>
          </ng-container>
        </ng-template>
      </p-table>
      <p-divider></p-divider>
      <div class="text-right">
        <button pButton
                label="Подробнее..."
                class="p-button-text p-button-secondary p-button-sm"
                routerLink="/task/accepted"
                [queryParams]="{} | concatDateParams:activePeriodName">
        </button>
      </div>
    </p-card>
  </div>
</div>

<ng-template #taskStatsSkeleton>
  <tr *ngFor="let _ of skeletonItems">
    <td>
      <p-skeleton width="100%"></p-skeleton>
    </td>
  </tr>
</ng-template>
