<p-card styleClass="h-full">
  <h3 class="mt-2 mb-0">Истекающие договоры
    <ng-container *ngIf="contracts?.amount">({{contracts.amount}})</ng-container>
  </h3>
  <p-divider></p-divider>
  <p-tabMenu [model]="contractMenuItems" [activeItem]="activeContractMenuItem">
    <ng-template pTemplate="item" let-item>
      <span class="fw-bold">
        {{item.label}}
        <ng-container *ngIf="contracts">({{contracts[item.state.name]?.length || 0}})</ng-container>
      </span>
    </ng-template>
  </p-tabMenu>

  <crm-table name="dashboard.expiringContracts"
             [loading]="!contracts"
             [values]="contracts && contracts[activeContractMenuItem.state.name]"
             [columns]="contractTableColumns"
             scrollHeight="245px">
    <ng-template #rowTemplate let-contract="item">
      <tr>
        <td>
          <div>
            <a routerLink="/contract/card/{{toContract(contract).id}}">{{toContract(contract).name}} {{toContract(contract).number}}</a>
            <p>
              <a *ngIf="toContract(contract).person"
                 class="greytext"
                 routerLink="/client/person/card/{{toContract(contract).person.id}}">
                {{toContract(contract).person.name}}
              </a>
            </p>
          </div>
        </td>
        <td>{{toContract(contract).days | number}}</td>
      </tr>
    </ng-template>
  </crm-table>

</p-card>
