import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InformerTypeEnum } from '@zaoblako/shared/common/enums/informer.type.enum';
import { NotificationSocketInterface } from '@zaoblako/shared/modules/notification/interfaces/notification.socket.interface';
import { NotificationService } from '@zaoblako/web/core/services/notification.service';
import { AuthService } from '@zaoblako/web/core/services/security/auth.service';
import { WebsocketNotificationService } from '@zaoblako/web/core/services/websocket-notification.service';
import { ConvertNotificationToLinkPipe } from '@zaoblako/web/shared/pipes/convert-notification-to-link.pipe';
import { Toast } from 'primeng/toast';

@Component({
  selector: 'crm-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss']
})
export class NotificationPanelComponent implements OnInit {

  @Input() public minimized: boolean;

  @Input() public isLk = false;

  public constructor(private readonly authService: AuthService,
                     private readonly cdRef: ChangeDetectorRef,
                     private readonly convertNotificationToLinkPipe: ConvertNotificationToLinkPipe,
                     private readonly websocketNotificationService: WebsocketNotificationService,
                     private readonly notificationService: NotificationService,
                     private readonly router: Router) {
  }

  public get InformerType() {
    return InformerTypeEnum;
  }

  public ngOnInit() {
    this.websocketNotificationService.createWSConnection(this.authService.getToken());

    this.notificationService.unreadCount().subscribe(unreadCount => {
      this.notificationService.unreadCount$.next(unreadCount?.result);
    });
  }

  public toSocketNotification(notification: any) {
    return notification as NotificationSocketInterface;
  }

  public onNotificationClick(toast: Toast, notification: NotificationSocketInterface) {
    toast.messages = toast.messages.filter(message => message.data.id !== notification.id);
    this.cdRef.detectChanges();

    this.notificationService.read(notification.id, notification.informerType).subscribe(() => {
      this.websocketNotificationService.socketNotificationRead$.next(notification);
    });
    this.router.navigate([this.convertNotificationToLinkPipe.transform(notification)]).catch(console.error);
  }
}
