<div class="grid p-align-center grid-nogutter">
  <div class="lg:col-8 xl:col-9 sm:col-0 md:col-0 pb-0 security-left-column hidden lg:inline-flex"></div>
  <div class="col ml-6 mr-6 pb-2 security-right-column">
    <div class="align-items-center text-center my-6">
      <img alt="Логотип АТРИУМ" src="/assets/images/logo.svg" height="220px">
    </div>

    <div *ngIf="visibleForm" class="p-fluid">

      <div class="field">
        <label class="fw-bold required-field">Название компании</label>
        <input type="text" pInputText [(ngModel)]="register.client.name" [ngClass]="{ 'ng-invalid ng-dirty': errorsClient?.name }">
        <small *ngIf="errorsClient?.name" class="p-error">{{ errorsClient?.name | join }}</small>
      </div>

      <div class="field">
        <label class="fw-bold required-field">Ф.И.О.</label>
        <input type="text" pInputText [(ngModel)]="register.user.name" [ngClass]="{ 'ng-invalid ng-dirty': errorsUser?.name }">
        <small *ngIf="errorsUser?.name" class="p-error">{{ errorsUser?.name | join }}</small>
      </div>

      <div class="field">
        <label class="fw-bold required-field">Телефон</label>
        <p-inputMask type="text"
                     mask="+9 (999) 999-99-99"
                     placeholder="+7 (999) 999-99-99"
                     [unmask]="true"
                     [(ngModel)]="register.user.phone"
                     [ngClass]="{ 'ng-invalid ng-dirty': errorsUser?.phone }">
        </p-inputMask>
        <small *ngIf="errorsUser?.phone" class="p-error">{{ errorsUser?.phone | join }}</small>
      </div>

      <div class="field">
        <label class="fw-bold required-field">Email</label>
        <input type="email" pInputText [(ngModel)]="register.user.email" [ngClass]="{ 'ng-invalid ng-dirty': errorsUser?.email }">
        <small *ngIf="errorsUser?.email" class="p-error">{{ errorsUser?.email | join }}</small>
      </div>

      <div class="field">
        <label class="fw-bold required-field">Адрес сайта</label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">http(s)://</span>
          <input type="text" pInputText [(ngModel)]="register.task.site" [ngClass]="{ 'ng-invalid ng-dirty': errorsTask?.site }">
        </div>
        <small *ngIf="errorsTask?.site" class="p-error">{{ errorsTask?.site | join }}</small>
      </div>

      <div class="field">
        <label class="fw-bold required-field">Площадь, от (м<sup>2</sup>)</label>
        <p-inputNumber class="w-full" [(ngModel)]="register.task.squareFrom" [ngClass]="{ 'ng-invalid ng-dirty': errorsTask?.squareFrom }"></p-inputNumber>
        <small *ngIf="errorsTask?.squareFrom" class="p-error">{{ errorsTask?.squareFrom | join }}</small>
      </div>

      <div class="field">
        <label class="fw-bold required-field">Площадь, до (м<sup>2</sup>)</label>
        <p-inputNumber class="w-full" [(ngModel)]="register.task.squareTo" [ngClass]="{ 'ng-invalid ng-dirty': errorsTask?.squareTo }"></p-inputNumber>
        <small *ngIf="errorsTask?.squareTo" class="p-error">{{ errorsTask?.squareTo | join }}</small>
      </div>

      <div class="field">
        <label class="fw-bold required-field">Торговые марки</label>
        <input type="text" pInputText [(ngModel)]="register.task.trademarks" [ngClass]="{ 'ng-invalid ng-dirty': errorsTask?.trademarks }">
        <small *ngIf="errorsTask?.trademarks" class="p-error">{{ errorsTask?.trademarks | join }}</small>
      </div>

      <div class="field">
        <label class="fw-bold required-field">Виды деятельности</label>
        <input type="text" pInputText [(ngModel)]="register.task.activities" [ngClass]="{ 'ng-invalid ng-dirty': errorsTask?.activities }">
        <small *ngIf="errorsTask?.activities" class="p-error">{{ errorsTask?.activities | join }}</small>
      </div>

      <div class="field">
        <label class="fw-bold required-field">Планируемый оборот в месяц</label>
        <div class="p-inputgroup">
          <p-inputNumber class="w-full" [(ngModel)]="register.task.plans" [ngClass]="{ 'ng-invalid ng-dirty': errorsTask?.plans }"></p-inputNumber>
          <span class="p-inputgroup-addon">руб.</span>
        </div>
        <small *ngIf="errorsTask?.plans" class="p-error">{{ errorsTask?.plans | join }}</small>
      </div>

      <div class="field">
        <label class="fw-bold required-field">Желаемая дата начала деятельности</label>
        <crm-datepicker [(value)]="register.task.dateFrom" [class]="errorsTask?.dateFrom ? 'ng-invalid ng-dirty' : ''"></crm-datepicker>
        <small *ngIf="errorsTask?.dateFrom" class="p-error">{{ errorsTask?.dateFrom | join }}</small>
      </div>

      <div class="field">
        <label class="fw-bold required-field">Желаемая арендная плата в месяц</label>
        <p-inputNumber class="w-full" [(ngModel)]="register.task.rentMoth" [ngClass]="{ 'ng-invalid ng-dirty': errorsTask?.rentMoth }"></p-inputNumber>
        <small *ngIf="errorsTask?.rentMoth" class="p-error">{{ errorsTask?.rentMoth | join }}</small>
      </div>

      <div class="field">
        <div class="p-field-checkbox">
          <p-checkbox binary="true" inputId="binary" [ngClass]="{ 'ng-invalid ng-dirty': terms?.error }" [(ngModel)]="terms.is"></p-checkbox>
          <label class="ml-2"><a target="_blank" routerLink="/license">Согласен с условиями использования сервиса</a></label>
        </div>
        <small class="p-error" *ngIf="terms?.error">{{ terms.error }}</small>
      </div>

      <div class="flex p-justify-end grid-nogutter mt-2">
        <button loadingIcon="pi pi-spinner pi-spin" pButton class="p-button" label="Отправить заявку" [loading]="loading" (click)="clickRegister()"></button>
      </div>

    </div>

    <div *ngIf="!visibleForm" class="p-fluid">
      <p-messages [enableService]="false" severity="info">
        <ng-template pTemplate>
          <div class="text-center">
            <p><strong>Вы успешно зарегистрировались в системе!</strong></p>
            <p>На указанный вами email отправлено письмо с данными для входа в систему.</p>
          </div>
        </ng-template>
      </p-messages>
    </div>

    <div class="error-divider" *ngIf="errBack?.message">
      <p-messages [value]="[{ severity: 'warn', detail: this.errBack?.message }]" [enableService]="false"></p-messages>
    </div>
    <div class="flex justify-content-between mt-4">
      <a class="pointer" routerLink="/login">Вход для зарегистрированных пользователей</a>
    </div>
    <div class="flex p-justify-end mt-6 copyright">
      <small>Все права защищены. ⓒ {{ footerYear }}</small>
    </div>

  </div>
</div>
