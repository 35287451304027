import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from '@zaoblako/web/core/components/app.component';
import { AclInterceptor } from '@zaoblako/web/core/interceptors/acl.interceptor';
import { ErrorInterceptor } from '@zaoblako/web/core/interceptors/error.interceptor';
import { TokenInterceptor } from '@zaoblako/web/core/interceptors/token.interceptor';
import { DashboardModule } from '@zaoblako/web/modules/dashboard/dashboard.module';
import 'd3';
import * as moment from 'moment-timezone';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { CoreModule } from './core/core.module';

registerLocaleData(localeRu, 'ru');
moment.locale('ru');

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    ButtonModule,
    BrowserAnimationsModule,
    PanelModule,
    InputTextModule,
    DashboardModule
  ],
  providers: [
    [
      { provide: LOCALE_ID, useValue: 'ru-RU' }
    ],
    [
      { provide: HTTP_INTERCEPTORS, useClass: AclInterceptor, multi: true }
    ],
    [
      { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
    ],
    [
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ]
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
