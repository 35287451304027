import { Injectable } from '@angular/core';
import { FindQueryDto } from '@zaoblako/shared/common/dtos/find.query.dto';
import { NotificationSettingFormDto } from '@zaoblako/shared/modules/security/dtos/notifications/settings/notification.setting.form.dto';
import { NotificationSettingsFindDto } from '@zaoblako/shared/modules/security/dtos/notifications/settings/notification.settings.find.dto';
import { UserChangePasswordDto } from '@zaoblako/shared/modules/security/dtos/user/user.change.password.dto';
import { UserEmailDto } from '@zaoblako/shared/modules/security/dtos/user/user.email.dto';
import { UserFindDto } from '@zaoblako/shared/modules/security/dtos/user/user.find.dto';
import { UserListFindDto } from '@zaoblako/shared/modules/security/dtos/user/user.list.find.dto';
import { UserManagerCreateDto } from '@zaoblako/shared/modules/security/dtos/user/user.manager.create.dto';
import { StateServiceRequestOptions } from '@zaoblako/web/core/models/state-service-request-options.model';
import { BaseStateService } from '@zaoblako/web/core/services/base-state-service';
import { UserService } from '@zaoblako/web/core/services/security/user.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserStateService extends BaseStateService {

  public cachedEmployees: UserFindDto[];

  public constructor(private readonly userService: UserService) {
    super();
    this.baseService = userService;
  }

  public findEmployees(queryParams: FindQueryDto, options?: StateServiceRequestOptions): Observable<UserListFindDto> {
    if (options?.withCache && this.cachedEmployees) {
      return of({
        limit: 0,
        offset: 0,
        count: 0,
        users: this.cachedEmployees
      });
    }
    return this.userService.findEmployees(queryParams).pipe(tap(data => {
      if (options?.withCache) {
        this.cachedEmployees = data.users;
      }
    }));
  }

  public findClients(queryParams: FindQueryDto): Observable<UserListFindDto> {
    return this.userService.findClients(queryParams);
  }

  public findClientUsers(): Observable<UserFindDto[]> {
    return this.userService.findClientUsers();
  }

  public createManager(body: UserManagerCreateDto): Observable<UserFindDto> {
    return this.userService.createManager(body);
  }

  public restorePassword(body: UserEmailDto): Observable<null> {
    return this.userService.restorePassword(body);
  }

  public changePassword(body: UserChangePasswordDto): Observable<null> {
    return this.userService.changePassword(body);
  }

  public notificationSettings(): Observable<NotificationSettingsFindDto> {
    return this.userService.notificationSettings();
  }

  public updateNotificationSettings(id: number, body: NotificationSettingFormDto): Observable<null> {
    return this.userService.updateNotificationSettings(id, body);
  }
}
