import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleEnum } from '@zaoblako/shared/modules/security/enums/role.enum';
import { PageNotFoundComponent } from '@zaoblako/web/core/components/page-not-found/page-not-found.component';
import { AuthGuard } from '@zaoblako/web/core/guards/auth.guard';
import { RoleGuard } from '@zaoblako/web/core/guards/role.guard';
import { LoginComponent } from '@zaoblako/web/modules/security/components/login/login.component';
import { RegisterComponent } from '@zaoblako/web/modules/security/components/register/register.component';
import { CommonLayoutComponent } from '@zaoblako/web/shared/layouts/common.layout.component';

const routes: Routes = [
  {
    path: '',
    component: CommonLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [
        RoleEnum.ROLE_ACCOUNTANT,
        RoleEnum.ROLE_ADMIN,
        RoleEnum.ROLE_ARCHITECT,
        RoleEnum.ROLE_CHIEF_ME,
        RoleEnum.ROLE_DEPUTY_HEAD,
        RoleEnum.ROLE_DIRECTOR,
        RoleEnum.ROLE_EMPLOYEE,
        RoleEnum.ROLE_FREELANCE,
        RoleEnum.ROLE_HEAD_DEPARTMENT,
        RoleEnum.ROLE_HEAD_ENGINEERING,
        RoleEnum.ROLE_HEAD_OPERATION,
        RoleEnum.ROLE_HEAD_PE,
        RoleEnum.ROLE_HEAD_SECURITY,
        RoleEnum.ROLE_INVESTOR,
        RoleEnum.ROLE_LAWYER,
        RoleEnum.ROLE_RENTAL_MANAGER,
        RoleEnum.ROLE_SYS_INTEGRATION,
        RoleEnum.ROLE_SYS_MANAGER,
        RoleEnum.ROLE_SYS_SUPPORT,
        RoleEnum.ROLE_MEETING
      ],
      included: true
    },

    children: [
      {
        path: '',
        loadChildren: () => import('../modules/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'task',
        loadChildren: () => import('../modules/task/task.module').then((m) => m.TaskModule)
      },
      {
        path: 'budget',
        loadChildren: () => import('../modules/budget/budget.module').then((m) => m.BudgetModule)
      },
      {
        path: 'client',
        loadChildren: () => import('../modules/client/client.module').then((m) => m.ClientModule)
      },
      {
        path: 'client-new',
        loadChildren: () => import('../modules/client-new/client-new.module').then((m) => m.ClientNewModule)
      },
      {
        path: 'location',
        loadChildren: () => import('../modules/location/location.module').then((m) => m.LocationModule)
      },
      {
        path: 'user',
        loadChildren: () => import('../modules/security/security.module').then((m) => m.SecurityModule)
      },
      {
        path: 'contract',
        loadChildren: () => import('../modules/contract/contract.module').then((m) => m.ContractModule)
      },
      {
        path: 'document',
        loadChildren: () => import('../modules/document/document.module').then((m) => m.DocumentModule)
      },
      {
        path: 'debarcader',
        loadChildren: () => import('../modules/debarcader/debarcader.module').then((m) => m.DebarcaderModule)
      },
      {
        path: 'report',
        loadChildren: () => import('../modules/report/report.module').then((m) => m.ReportModule)
      },
      {
        path: 'help',
        loadChildren: () => import('../modules/help/help.module').then((m) => m.HelpModule)
      },
      {
        path: 'activity',
        loadChildren: () => import('../modules/activity/activity.module').then((m) => m.ActivityModule)
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'manager',
    canActivate: [RoleGuard],
    loadChildren: () => import('../modules/cabinets/manager/manager.module').then((m) => m.ManagerModule),
    data: {
      roles: [RoleEnum.ROLE_USER_DIRECTOR, RoleEnum.ROLE_USER_MANAGER, RoleEnum.ROLE_USER],
      included: true
    }
  },
  {
    path: 'coowner',
    canActivate: [RoleGuard],
    loadChildren: () => import('../modules/cabinets/coowner/coowner.module').then((m) => m.CoownerModule),
    data: {
      roles: [RoleEnum.ROLE_COOWNER],
      included: true
    }
  },
  {
    path: 'operation',
    canActivate: [RoleGuard],
    loadChildren: () => import('../modules/cabinets/coowner/coowner.module').then((m) => m.CoownerModule),
    data: {
      roles: [RoleEnum.ROLE_OPERATION],
      included: true
    }
  },
  {
    path: 'security',
    canActivate: [RoleGuard],
    loadChildren: () => import('../modules/cabinets/security/security-lk.module').then((m) => m.SecurityLkModule),
    data: {
      roles: [RoleEnum.ROLE_SECURITY],
      included: true
    }
  },
  {
    path: 'reglament',
    loadChildren: () => import('../modules/reglament/reglament.module').then((m) => m.ReglamentModule)
  },
  {
    path: 'license',
    loadChildren: () => import('../modules/license/license.module').then((m) => m.LicenseModule)
  },
  {
    path: 'notfound',
    canActivate: [AuthGuard],
    component: PageNotFoundComponent
  },
  {
    path: '**',
    canActivate: [AuthGuard],
    component: PageNotFoundComponent
  }
];

// const maintenanceRoutes = [
//   {
//     path: '**',
//     component: MaintenancePageComponent
//   }
// ];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'ignore',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [
    RouterModule
  ],
  providers: [],
  bootstrap: []
})
export class CoreRouter {

}
