export enum NotificationGroupEnum {
  OFD_CONTROL = 'OFD_CONTROL',
  TASK = 'TASK',
  // PERSON = 'PERSON',
  CONTRACT = 'CONTRACT',
  ROOM = 'ROOM',
  // USER = 'USER',
  // INTEGRATION = 'INTEGRATION',
  // SYSTEM = 'SYSTEM',
  CLIENT = 'CLIENT'
}
