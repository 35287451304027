import { BaseDto } from '@zaoblako/shared/common/dtos/base.dto';
import { ClientRegistrationDto } from '@zaoblako/shared/modules/security/dtos/user/client.registration.dto';
import { TaskRegistrationDto } from '@zaoblako/shared/modules/security/dtos/user/task.registration.dto';
import { UserRegistrationDto } from '@zaoblako/shared/modules/security/dtos/user/user.registration.dto';

import { Expose, Type } from 'class-transformer';


@Expose()
export class RegistrationDto extends BaseDto {

  @Expose()
  @Type(() => UserRegistrationDto)
  public user: UserRegistrationDto;

  @Expose()
  @Type(() => ClientRegistrationDto)
  public client: ClientRegistrationDto;

  @Expose()
  @Type(() => TaskRegistrationDto)
  public task: TaskRegistrationDto;

}
