import { Injectable } from '@angular/core';
import { SocketIOMessageInterface } from '@zaoblako/shared/common/interfaces/socket.io.message.interface';
import { NotificationSocketInterface } from '@zaoblako/shared/modules/notification/interfaces/notification.socket.interface';
import { MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { io, Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class WebsocketNotificationService {

  public socket: Socket;
  public message$: Subject<SocketIOMessageInterface<NotificationSocketInterface>> = new Subject();
  public socketNotificationRead$: Subject<NotificationSocketInterface> = new Subject();

  public constructor(private readonly messageService: MessageService) {
  }

  public createWSConnection(authToken: string) {
    this.socket = io('/notification', {
      path: '/api/socket/connect',
      auth: {
        token: authToken
      },
      withCredentials: true
    });

    this.socket.on('disconnect', (reason) => {
      console.error('ws disconnect', reason);
      if (reason === 'io server disconnect') {
        this.socket.connect();
      }
    });

    this.socket.on('message', (message) => {
      this.message$.next(message);
    });
  }

  public disconnectWS() {
    if (this.socket?.connected) {
      this.socket.disconnect();
    }
  }

  public getNewMessage(): Observable<SocketIOMessageInterface<NotificationSocketInterface>> {
    return this.message$.asObservable();
  };

  public showNotificationPopup(notification: NotificationSocketInterface) {
    this.messageService.add({ key: 'notification', severity: 'notification', data: notification, life: 10000 });
  }
}
