import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonShowAndHideComponent } from '@zaoblako/web/shared/components/button-show-and-hide/button-show-and-hide.component';
import { CardWidgetComponent } from '@zaoblako/web/shared/components/card-widget/card-widget.component';
import { GlobalSearchComponent } from '@zaoblako/web/shared/components/global-search/global-search.component';
import { SettingsComponent } from '@zaoblako/web/shared/components/settings/settings.component';
import { UserAvatarComponent } from '@zaoblako/web/shared/components/user-avatar/user-avatar.component';
import { AnalyticWidgetCardComponent } from '@zaoblako/web/shared/dumbs/analytic-widget-card/analytic-widget-card.component';
import { CommentsNewComponent } from '@zaoblako/web/shared/dumbs/comments-new/comments-new.component';
import { DatepickerComponent } from '@zaoblako/web/shared/dumbs/datepicker/datepicker.component';
import { DaterangepickerComponent } from '@zaoblako/web/shared/dumbs/daterangepicker/daterangepicker.component';
import { ClientMultiSelectComponent } from '@zaoblako/web/shared/dumbs/dropdowns/client/client-multi-select/client-multi-select.component';
import { ContractServiceTypeSingleSelectComponent } from '@zaoblako/web/shared/dumbs/dropdowns/contract/contract-service-type-single-select/contract-service-type-single-select.component';
import { MultiSelectComponent } from '@zaoblako/web/shared/dumbs/dropdowns/multi-select/multi-select.component';
import { SingleSelectComponent } from '@zaoblako/web/shared/dumbs/dropdowns/single-select/single-select.component';
import { UserAndGroupMultiSelectComponent } from '@zaoblako/web/shared/dumbs/dropdowns/task/auditor-multi-select/user-and-group-multi-select.component';
import { DirectionSingleSelectComponent } from '@zaoblako/web/shared/dumbs/dropdowns/task/direction-single-select/direction-single-select.component';
import { TaskFieldTypeSingleSelectComponent } from '@zaoblako/web/shared/dumbs/dropdowns/task/task-field-type-single-select/task-field-type-single-select.component';
import { RoomsFormComponent } from '@zaoblako/web/shared/dumbs/rooms-panel/rooms-form/rooms-form.component';
import { TableComponent } from '@zaoblako/web/shared/dumbs/table/table.component';
import { TaskFormComponent } from '@zaoblako/web/shared/dumbs/task-form/task-form.component';
import { TaskFieldValuesComponent } from '@zaoblako/web/shared/dumbs/task/task-field-values/task-field-values.component';
import { TaskHistoryComponent } from '@zaoblako/web/shared/dumbs/task/task-history/task-history.component';
import { CommonLayoutComponent } from '@zaoblako/web/shared/layouts/common.layout.component';
import { ApiUrlPipe } from '@zaoblako/web/shared/pipes/api-url.pipe';
import { ConcatDateParamsPipe } from '@zaoblako/web/shared/pipes/concat-date-params.pipe';
import { ContractIndexClassPipe } from '@zaoblako/web/shared/pipes/contract-index-class.pipe';
import { ContractServiceCodePipe } from '@zaoblako/web/shared/pipes/contract-service-code.pipe';
import { ContractServiceTypePipe } from '@zaoblako/web/shared/pipes/contract-service-type.pipe';
import { ContractStatusColorPipe } from '@zaoblako/web/shared/pipes/contract-status-color.pipe';
import { DebarcaderNextStatusesPipe } from '@zaoblako/web/shared/pipes/debarcader-next-status.pipe';
import { FileSizePipe } from '@zaoblako/web/shared/pipes/file-size.pipe';
import { FileTypePipe } from '@zaoblako/web/shared/pipes/file-type.pipe';
import { FilterByQueryPipe } from '@zaoblako/web/shared/pipes/filter-by-query.pipe';
import { FilterByPipe } from '@zaoblako/web/shared/pipes/filter-by.pipe';
import { HumanDatePipe } from '@zaoblako/web/shared/pipes/human-date.pipe';
import { HumanRangePipe } from '@zaoblako/web/shared/pipes/human-range.pipe';
import { JoinPipe } from '@zaoblako/web/shared/pipes/join.pipe';
import { KeyValueFilterPipe } from '@zaoblako/web/shared/pipes/key-value-filter.pipe';
import { NestedPropertyPipe } from '@zaoblako/web/shared/pipes/nested-property.pipe';
import { NotificationGroupNamePipe } from '@zaoblako/web/shared/pipes/notification-group-name.pipe';
import { OrderByPipe } from '@zaoblako/web/shared/pipes/order-by.pipe';
import { PhonePipe } from '@zaoblako/web/shared/pipes/phone.pipe';
import { SafeHtmlPipe } from '@zaoblako/web/shared/pipes/safe-html.pipe';
import { TaskAgreementStatusPipe } from '@zaoblako/web/shared/pipes/task-agreement-status.pipe';
import { TaskColorPipe } from '@zaoblako/web/shared/pipes/task-color.pipe';
import { TaskFieldTypePipe } from '@zaoblako/web/shared/pipes/task-field-type.pipe';
import { TaskListUrlByStatusPipe } from '@zaoblako/web/shared/pipes/task-list-url-by-status.pipe';
import { TaskTemplateTypePipe } from '@zaoblako/web/shared/pipes/task-template-type.pipe';
import { TimeFromTodayPipe } from '@zaoblako/web/shared/pipes/time-from-today.pipe';
import { TruncatePipe } from '@zaoblako/web/shared/pipes/truncate.pipe';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SkeletonModule } from 'primeng/skeleton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TagModule } from 'primeng/tag';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { ActionBarComponent } from './components/action-bar/action-bar.component';
import { ActivityNotificationComponent } from './components/activity-notification/activity-notification.component';
import { CabinetHeaderComponent } from './components/cabinet-header/cabinet-header.component';
import { DebarcaderCalendarAcceptComponent } from './components/debarcader-calendar-accept/debarcader-calendar-accept.component';
import { GoBackButtonComponent } from './components/go-back-button/go-back-button.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { NotificationPanelComponent } from './components/notification-panel/notification-panel.component';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { AgreementStatusComponent } from './dumbs/agreement-status/agreement-status.component';
import { CardTableComponent } from './dumbs/card-table/card-table.component';
import { ChartGroupMenuComponent } from './dumbs/chart-group-menu/chart-group-menu.component';
import { ChartComponent } from './dumbs/chart/chart.component';
import { CommentsComponent } from './dumbs/comments/comments.component';
import { ContractServiceInfoComponent } from './dumbs/contract/contract-service-info/contract-service-info.component';
import { DaypickerComponent } from './dumbs/daypicker/daypicker.component';
import { AreaOccupationStatusesComponent } from './dumbs/debarcader/area-occupation-statuses/area-occupation-statuses.component';
import { CurrencySingleSelectComponent } from './dumbs/dropdowns/client/currency-single-select/currency-single-select.component';
import { PersonSingleSelectComponent } from './dumbs/dropdowns/client/person-single-select/person-single-select.component';
import { ContractMultiSelectComponent } from './dumbs/dropdowns/contract/contract-multi-select/contract-multi-select.component';
import { ContractServiceCodeSingleSelectComponent } from './dumbs/dropdowns/contract/contract-service-code-single-select/contract-service-code-single-select.component';
import { ContractServiceTypeMultiSelectComponent } from './dumbs/dropdowns/contract/contract-service-type-multi-select/contract-service-type-multi-select.component';
import { ContractSingleSelectComponent } from './dumbs/dropdowns/contract/contract-single-select/contract-single-select.component';
import { FloorMultiSelectComponent } from './dumbs/dropdowns/location/floor-multi-select/floor-multi-select.component';
import { FloorSingleSelectComponent } from './dumbs/dropdowns/location/floor-single-select/floor-single-select.component';
import { RoomMultiSelectComponent } from './dumbs/dropdowns/location/room-multi-select/room-multi-select.component';
import { ZoneMultiSelectComponent } from './dumbs/dropdowns/location/zone-multi-select/zone-multi-select.component';
import { TaskTemplateMultiSelectComponent } from './dumbs/dropdowns/task/task-template-multi-select/task-template-multi-select.component';
import { TaskTemplateSingleSelectComponent } from './dumbs/dropdowns/task/task-template-single-select/task-template-single-select.component';
import { TaskTemplateTypeSingleSelectComponent } from './dumbs/dropdowns/task/task-template-type-single-select/task-template-type-single-select.component';
import { FileComponent } from './dumbs/file/file.component';
import { PaymentAndOutgoingGroupMenuComponent } from './dumbs/payment-and-outgoing-group-menu/payment-and-outgoing-group-menu.component';
import { RoomsPanelComponent } from './dumbs/rooms-panel/rooms-panel.component';
import { SpinnerComponent } from './dumbs/spinner/spinner.component';
import { TaskAgreementCardComponent } from './dumbs/task/task-agreement-card/task-agreement-card.component';
import { TaskFieldTableRevenueComponent } from './dumbs/task/task-field-table-revenue/task-field-table-revenue.component';
import { TaskFieldTablesComponent } from './dumbs/task/task-field-tables/task-field-tables.component';
import { TaskFieldValueShowComponent } from './dumbs/task/task-field-value-show/task-field-value-show.component';
import { TaskFieldValuesTableComponent } from './dumbs/task/task-field-values-table/task-field-values-table.component';
import { TaskStepContractComponent } from './dumbs/task/task-step-contract/task-step-contract.component';
import { TaskStepInfoComponent } from './dumbs/task/task-step-info/task-step-info.component';
import { TaskStepRoomAgreeComponent } from './dumbs/task/task-step-room-agree/task-step-room-agree.component';
import { TaskStepsTimelineComponent } from './dumbs/task/task-steps-timeline/task-steps-timeline.component';
import { TaskStepsComponent } from './dumbs/task/task-steps/task-steps.component';
import { TextDatepickerComponent } from './dumbs/text-datepicker/text-datepicker.component';
import { WidgetManagerComponent } from './dumbs/widget-manager/widget-manager.component';
import { WidgetRoomComponent } from './dumbs/widget-room/widget-room.component';
import { BannerColorClassPipe } from './pipes/banner-color-class.pipe';
import { BannerColorNamePipe } from './pipes/banner-color-name.pipe';
import { BannerStatusNamePipe } from './pipes/banner-status-name.pipe';
import { ConvertDateToPeriodPipe } from './pipes/convert-date-to-period.pipe';
import { ConvertNotificationToLinkPipe } from './pipes/convert-notification-to-link.pipe';
import { PrimeNgChartTypeNamePipe } from './pipes/prime-ng-chart-type-name.pipe';
import { ClientTypeNamePipe } from './pipes/client-type-name.pipe';
import { ClientTypeColorPipe } from './pipes/client-type-color.pipe';

@NgModule({
  declarations: [
    ActionBarComponent,
    ApiUrlPipe,
    AreaOccupationStatusesComponent,
    ChartGroupMenuComponent,
    ClientMultiSelectComponent,
    CommentsComponent,
    CommonLayoutComponent,
    ConcatDateParamsPipe,
    ContractIndexClassPipe,
    ContractMultiSelectComponent,
    ContractServiceCodePipe,
    ContractServiceCodeSingleSelectComponent,
    ContractServiceTypeMultiSelectComponent,
    ContractServiceTypePipe,
    ContractServiceTypeSingleSelectComponent,
    ContractSingleSelectComponent,
    ContractSingleSelectComponent,
    CurrencySingleSelectComponent,
    DatepickerComponent,
    DaterangepickerComponent,
    DaypickerComponent,
    DebarcaderNextStatusesPipe,
    DirectionSingleSelectComponent,
    FileSizePipe,
    FileTypePipe,
    FilterByPipe,
    FilterByQueryPipe,
    FloorMultiSelectComponent,
    FloorSingleSelectComponent,
    FloorSingleSelectComponent,
    GlobalSearchComponent,
    GoBackButtonComponent,
    HumanDatePipe,
    HumanRangePipe,
    InfiniteScrollDirective,
    JoinPipe,
    KeyValueFilterPipe,
    MultiSelectComponent,
    NestedPropertyPipe,
    NotificationGroupNamePipe,
    OrderByPipe,
    PaymentAndOutgoingGroupMenuComponent,
    PersonSingleSelectComponent,
    PhonePipe,
    RoomMultiSelectComponent,
    SafeHtmlPipe,
    SingleSelectComponent,
    SpinnerComponent,
    TableComponent,
    TaskAgreementStatusPipe,
    TaskColorPipe,
    TaskFieldTypePipe,
    TaskFieldTypeSingleSelectComponent,
    TaskFieldValuesComponent,
    TaskHistoryComponent,
    TaskListUrlByStatusPipe,
    TaskTemplateMultiSelectComponent,
    TaskTemplateSingleSelectComponent,
    TaskTemplateTypePipe,
    TaskTemplateTypeSingleSelectComponent,
    TextDatepickerComponent,
    TimeFromTodayPipe,
    TruncatePipe,
    UserAvatarComponent,
    WidgetManagerComponent,
    ZoneMultiSelectComponent,
    TaskFieldValuesTableComponent,
    FileComponent,
    TaskFieldTablesComponent,
    TaskFieldValueShowComponent,
    UserAndGroupMultiSelectComponent,
    WidgetRoomComponent,
    AgreementStatusComponent,
    TaskAgreementCardComponent,
    WidgetRoomComponent,
    CardTableComponent,
    ContractServiceInfoComponent,
    CabinetHeaderComponent,
    TaskFormComponent,
    RoomsPanelComponent,
    RoomsFormComponent,
    AnalyticWidgetCardComponent,
    TaskStepInfoComponent,
    TaskStepsTimelineComponent,
    TaskStepsComponent,
    TaskFieldTableRevenueComponent,
    TaskStepRoomAgreeComponent,
    TaskStepContractComponent,
    DebarcaderCalendarAcceptComponent,
    NotificationPanelComponent,
    NotificationListComponent,
    SettingsComponent,
    ConvertNotificationToLinkPipe,
    ActivityNotificationComponent,
    ChartComponent,
    PrimeNgChartTypeNamePipe,
    ConvertDateToPeriodPipe,
    BannerStatusNamePipe,
    BannerColorNamePipe,
    BannerColorClassPipe,
    ClientTypeNamePipe,
    ClientTypeColorPipe,
    ContractStatusColorPipe,
    ButtonShowAndHideComponent,
    CardWidgetComponent,
    CommentsNewComponent
  ],
  imports: [
    AccordionModule,
    AvatarModule,
    AvatarModule,
    BadgeModule,
    BlockUIModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    ChartModule,
    CheckboxModule,
    ChipModule,
    ColorPickerModule,
    CommonModule,
    DividerModule,
    DropdownModule,
    EditorModule,
    FileUploadModule,
    FormsModule,
    InputMaskModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    ImageModule,
    ListboxModule,
    MenuModule,
    MenubarModule,
    MultiSelectModule,
    OverlayPanelModule,
    PanelMenuModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RippleModule,
    RouterModule,
    SkeletonModule,
    SlideMenuModule,
    TabMenuModule,
    TableModule,
    TagModule,
    TimelineModule,
    TooltipModule,
    ToastModule,
    PanelModule,
    ScrollPanelModule,
    SelectButtonModule,
    DialogModule
  ],
  exports: [
    AccordionModule,
    ActionBarComponent,
    AgreementStatusComponent,
    ApiUrlPipe,
    AreaOccupationStatusesComponent,
    AvatarModule,
    BadgeModule,
    BlockUIModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    ChartGroupMenuComponent,
    ChartModule,
    CheckboxModule,
    ChipModule,
    ClientMultiSelectComponent,
    ColorPickerModule,
    CommentsComponent,
    CommonLayoutComponent,
    ConcatDateParamsPipe,
    ContractIndexClassPipe,
    ContractMultiSelectComponent,
    ContractServiceCodePipe,
    ContractServiceCodeSingleSelectComponent,
    ContractServiceTypeMultiSelectComponent,
    ContractServiceTypePipe,
    ContractServiceTypeSingleSelectComponent,
    ContractSingleSelectComponent,
    CurrencySingleSelectComponent,
    DatepickerComponent,
    DaterangepickerComponent,
    DaypickerComponent,
    DebarcaderNextStatusesPipe,
    DirectionSingleSelectComponent,
    DividerModule,
    DropdownModule,
    EditorModule,
    FileUploadModule,
    FilterByQueryPipe,
    FileComponent,
    FileSizePipe,
    FileTypePipe,
    FloorMultiSelectComponent,
    FloorSingleSelectComponent,
    FormsModule,
    GlobalSearchComponent,
    GoBackButtonComponent,
    HumanDatePipe,
    HumanRangePipe,
    InfiniteScrollDirective,
    InputMaskModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    JoinPipe,
    KeyValueFilterPipe,
    ImageModule,
    ListboxModule,
    MenubarModule,
    MultiSelectComponent,
    NotificationGroupNamePipe,
    OrderByPipe,
    OverlayPanelModule,
    PaymentAndOutgoingGroupMenuComponent,
    PersonSingleSelectComponent,
    PhonePipe,
    ProgressSpinnerModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RippleModule,
    RoomMultiSelectComponent,
    SafeHtmlPipe,
    SingleSelectComponent,
    SkeletonModule,
    SpinnerComponent,
    TabMenuModule,
    TableComponent,
    TableModule,
    TagModule,
    TaskAgreementCardComponent,
    TaskAgreementStatusPipe,
    TaskColorPipe,
    TaskFieldTypePipe,
    TaskFieldTypeSingleSelectComponent,
    TaskFieldValuesComponent,
    TaskFieldValueShowComponent,
    TaskFieldValuesTableComponent,
    TaskFieldTablesComponent,
    TaskHistoryComponent,
    TaskListUrlByStatusPipe,
    TaskTemplateMultiSelectComponent,
    TaskTemplateSingleSelectComponent,
    TaskTemplateTypePipe,
    TaskTemplateTypeSingleSelectComponent,
    TextDatepickerComponent,
    TimeFromTodayPipe,
    TimelineModule,
    TooltipModule,
    TruncatePipe,
    UserAvatarComponent,
    WidgetManagerComponent,
    ZoneMultiSelectComponent,
    FilterByPipe,
    UserAndGroupMultiSelectComponent,
    WidgetRoomComponent,
    CardTableComponent,
    ContractServiceInfoComponent,
    CabinetHeaderComponent,
    TaskFormComponent,
    RoomsPanelComponent,
    RoomsFormComponent,
    AnalyticWidgetCardComponent,
    TaskStepInfoComponent,
    TaskStepsTimelineComponent,
    TaskStepsComponent,
    TaskStepRoomAgreeComponent,
    TaskStepContractComponent,
    DebarcaderCalendarAcceptComponent,
    SettingsComponent,
    ChartComponent,
    ConvertDateToPeriodPipe,
    BannerStatusNamePipe,
    BannerColorNamePipe,
    BannerColorClassPipe,
    ContractStatusColorPipe,
    ButtonShowAndHideComponent,
    CardWidgetComponent,
    CommentsNewComponent,
    DialogModule
  ],
  providers: [TaskFieldTypePipe, ApiUrlPipe, ConvertNotificationToLinkPipe, DialogService]
})
export class SharedModule {}
