import { Injectable } from '@angular/core';
import { RegistrationDto } from '@zaoblako/shared/modules/security/dtos/user/registration.dto';
import { UserFindDto } from '@zaoblako/shared/modules/security/dtos/user/user.find.dto';
import { BaseService } from '@zaoblako/web/core/services/base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterService extends BaseService {

  public register(body: RegistrationDto): Observable<UserFindDto> {
    return this.http.post<UserFindDto>('/register', body);
  }
}
