import { Component, Input } from '@angular/core';
import { IndexGroupDateReportDto } from '@zaoblako/shared/modules/report/dtos/index/index.group.date.report.dto';
import { DashboardPeriodType } from '@zaoblako/web/core/types/dashboard-period.type';

@Component({
  selector: 'crm-dashboard-stats-widgets',
  templateUrl: './dashboard-stats-widgets.component.html',
  styles: []
})
export class DashboardStatsWidgetsComponent {

  @Input() public activePeriodLabel: string;
  @Input() public activePeriodName: DashboardPeriodType;
  @Input() public periodStats: IndexGroupDateReportDto;

  @Input() public roomStats: {
    all: number;
    active: number;
  };

  @Input() public debt: {
    amount: number;
  };

  public get roomsLoad() {
    return 100 * this.roomStats.active / this.roomStats.all;
  }

}
