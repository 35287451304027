import { Component, Input } from '@angular/core';
import { HistoryActionEnum } from '@zaoblako/shared/modules/catalog/enums/history.action.enum';
import { TaskHistoryFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.history.find.dto';

@Component({
  selector: 'crm-task-history',
  templateUrl: './task-history.component.html'
})
export class TaskHistoryComponent {

  @Input() public taskHistory: TaskHistoryFindDto[];
  @Input() public loading = false;

  @Input() public showTaskInfo = false;

  public minimized = true;

  public skeletonItems = Array(5).fill(null);

  public get HistoryActionEnum() {
    return HistoryActionEnum;
  }

  public minimizeButtonLabel(): string {
    return this.minimized
      ? `Показать еще ${this.taskHistory.length - 25}`
      : 'Свернуть';
  }

}
