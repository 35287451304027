import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchDto } from '@zaoblako/shared/modules/search/dtos/search.dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public constructor(private readonly http: HttpClient) {
  }

  public search(query: string): Observable<SearchDto> {
    return this.http.get<SearchDto>('/search', { params: { text: query } });
  }
}
