import { Injectable } from '@angular/core';
import { FindQueryDto } from '@zaoblako/shared/common/dtos/find.query.dto';
import { NotificationSettingFormDto } from '@zaoblako/shared/modules/security/dtos/notifications/settings/notification.setting.form.dto';
import { NotificationSettingsFindDto } from '@zaoblako/shared/modules/security/dtos/notifications/settings/notification.settings.find.dto';
import { UserChangePasswordDto } from '@zaoblako/shared/modules/security/dtos/user/user.change.password.dto';
import { UserEmailDto } from '@zaoblako/shared/modules/security/dtos/user/user.email.dto';
import { UserFindDto } from '@zaoblako/shared/modules/security/dtos/user/user.find.dto';
import { UserListFindDto } from '@zaoblako/shared/modules/security/dtos/user/user.list.find.dto';
import { UserLoginDto } from '@zaoblako/shared/modules/security/dtos/user/user.login.dto';
import { UserLoginResponseDto } from '@zaoblako/shared/modules/security/dtos/user/user.login.response.dto';
import { UserManagerCreateDto } from '@zaoblako/shared/modules/security/dtos/user/user.manager.create.dto';
import { BaseService } from '@zaoblako/web/core/services/base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  protected baseUrl = '/user';

  public login(login: UserLoginDto): Observable<UserLoginResponseDto> {
    return this.http.post<UserLoginResponseDto>('/user/login', login);
  }

  public check(): Observable<null> {
    return this.http.get<null>('/user/check');
  }

  public findEmployees(queryParams: FindQueryDto): Observable<UserListFindDto> {
    return this.http.get<UserListFindDto>('/user/employee', { params: queryParams as any });
  }

  public findClients(queryParams: FindQueryDto): Observable<UserListFindDto> {
    return this.http.get<UserListFindDto>('/user/user', { params: queryParams as any });
  }

  public findClientUsers(): Observable<UserFindDto[]> {
    return this.http.get<UserFindDto[]>('/user/client-user');
  }

  public createManager(body: UserManagerCreateDto): Observable<UserFindDto> {
    return this.http.post<UserFindDto>(`${this.baseUrl}/create-manager`, body);
  }

  public restorePassword(body: UserEmailDto): Observable<null> {
    return this.http.post<null>(`${this.baseUrl}/restore-password`, body);
  }

  public changePassword(body: UserChangePasswordDto): Observable<null> {
    return this.http.post<null>(`${this.baseUrl}/change-password`, body);
  }

  public notificationSettings(): Observable<NotificationSettingsFindDto> {
    return this.http.get<NotificationSettingsFindDto>(`${this.baseUrl}/notification-settings`);
  }

  public updateNotificationSettings(id: number, body: NotificationSettingFormDto): Observable<null> {
    return this.http.put<null>(`${this.baseUrl}/notification-settings/${id}`, body);
  }

}
