import { Component, Input } from '@angular/core';
import { DashboardPeriodType } from '@zaoblako/web/core/types/dashboard-period.type';

@Component({
  selector: 'crm-dashboard-stat-widget-card',
  templateUrl: './dashboard-stat-widget-card.component.html'
})
export class DashboardStatWidgetCardComponent {
  @Input() public title!: string;
  @Input() public description: string;
  @Input() public link!: string;
  @Input() public withPeriodQueryParams = false;
  @Input() public activePeriodName: DashboardPeriodType;
}
