import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLoginDto } from '@zaoblako/shared/modules/security/dtos/user/user.login.dto';
import { UserLoginResponseDto } from '@zaoblako/shared/modules/security/dtos/user/user.login.response.dto';
import { RoleEnum } from '@zaoblako/shared/modules/security/enums/role.enum';
import { UserService } from '@zaoblako/web/core/services/security/user.service';
import { WebsocketNotificationService } from '@zaoblako/web/core/services/websocket-notification.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedInUser: UserLoginResponseDto;

  public constructor(private readonly route: ActivatedRoute,
                     private readonly router: Router,
                     private readonly websocketNotificationService: WebsocketNotificationService,
                     private readonly userService: UserService) {

    if (localStorage.getItem('JWT_USER')) {

      this.loggedInUser = JSON.parse(localStorage.getItem('JWT_USER'));
    }
  }

  public get currentUser() {
    return this.loggedInUser;
  }

  public login(login: UserLoginDto): Observable<UserLoginResponseDto> {

    return this.userService.login(login)
               .pipe(tap(response => {
                 this.loggedInUser = response;

                 localStorage.setItem('JWT_TOKEN', response.token);
                 localStorage.setItem('JWT_USER', JSON.stringify(response));
               }));
  }

  public logout(url?: string) {

    localStorage.removeItem('JWT_TOKEN');
    localStorage.removeItem('JWT_USER');

    this.loggedInUser = undefined;
    this.websocketNotificationService.disconnectWS();

    this.router.navigate(['/login'], { queryParams: { url } }).catch(console.error);
  }

  public getToken() {
    return localStorage.getItem('JWT_TOKEN');
  }

  public updateLoggedUser(user: Partial<UserLoginResponseDto>) {
    this.loggedInUser = { ...this.loggedInUser, ...user };
    localStorage.setItem('JWT_USER', JSON.stringify(this.loggedInUser));
  }

  public isAuthenticated(): Promise<any> {
    if (!this.getToken()) {
      return Promise.reject(false);
    }
    return Promise.resolve(this.userService.check());
  }

  public can(url: string, access?: string) {
    if (!this.loggedInUser.acl) {
      return false;
    } else if (!this.loggedInUser.acl[url]) {
      return false;
    }
    if (access !== undefined) {
      if (this.loggedInUser.acl[url] < access) {
        return false;
      }
    }
    return true;
  }

  public hasRole(roles: RoleEnum | RoleEnum[]): boolean {
    return Array.isArray(roles)
      ? roles.some(role => this.currentUser.roles.includes(role))
      : this.currentUser.roles.includes(roles);
  }
}
