import { Component, HostListener, OnInit } from '@angular/core';
import { SearchDto } from '@zaoblako/shared/modules/search/dtos/search.dto';
import { KEY_CODE } from '@zaoblako/web/core/models/enums/keycode.enum';
import { SearchService } from '@zaoblako/web/core/services/search.service';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'crm-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss',
    '../../../../../../../node_modules/quill/dist/quill.core.css',
    '../../../../../../../node_modules/quill/dist/quill.snow.css'
  ]
})
export class GlobalSearchComponent implements OnInit {

  public search = new Subject<string>();
  public searchResults$: Observable<SearchDto>;

  public constructor(private readonly searchService: SearchService) {
  }

  public ngOnInit(): void {
    this.searchResults$ = this.search.pipe(
      map(query => query.trim()),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((query) => {
        if (!query || query === '' || query.length < 3) {
          return of(null);
        }
        return this.searchService.search(query);
      })
    );
  }

  @HostListener('document:keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent) {
    if (event.code === KEY_CODE.ESCAPE) {
      this.search.next('');
    }
  }

}
