import { Component, Input } from '@angular/core';
import { TaskByTypeReportDto } from '@zaoblako/shared/modules/report/dtos/task/task.by.type.report.dto';
import { TaskStatusTypeEnum } from '@zaoblako/shared/modules/task/enums/task.status.type.enum';
import { AuthService } from '@zaoblako/web/core/services/security/auth.service';
import { DashboardPeriodType } from '@zaoblako/web/core/types/dashboard-period.type';

@Component({
  selector: 'crm-dashboard-task-stats',
  templateUrl: './dashboard-task-stats.component.html'
})
export class DashboardTaskStatsComponent {

  @Input() public taskStats: TaskByTypeReportDto;
  @Input() public activePeriodLabel: string;
  @Input() public activePeriodName: DashboardPeriodType;

  public skeletonItems = Array(5).fill(null);

  public get TaskStatusTypeEnum() {
    return TaskStatusTypeEnum;
  }

  public get currentUser() {
    return this.authService.currentUser;
  }

  public constructor(private readonly authService: AuthService) {
  }

}
